import axios from "../helper/AxiosHelper";
import { paymentMethodCustomType } from "../helper/GlobalValueHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(
    `/paymentMethod/query?query=${JSON.stringify(query)}&fields=all`,
  );
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        type: data.type,
        isAvailable: data.is_available,
        name: data.name,
        icon: data.icon,
        code: data.code,
        priority: data.priority,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`paymentMethod/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    name: result.name,
    icon: result.icon,
    type: result.type,
    isAvailable: result.is_available,
    code: result.code,
    priority: result.priority,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    createdBy: result.created_by_info ? result.created_by_info.username : "",
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const create = async input => {
  const formData = new FormData();
  const data = {
    name: input.name,
    is_available: 1,
    type: input,
    icon: input.icon,
    priority: 0,
    upload_files: input.uploadFiles?.length || undefined,
  };

  formData.append("data", JSON.stringify(data));

  if (input.uploadFiles) {
    input.uploadFiles.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });
  }

  const response = await axios.post(`paymentMethod/create`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.result.insert_id;
};

const update = async (id, input) => {
  const formData = new FormData();
  const data = {
    name: input.name,
    is_available: input.isAvailable,
    type: input.type,
    icon: input.icon,
    priority: input.priority,
    upload_files: input.uploadFiles?.length || undefined,
  };

  formData.append("data", JSON.stringify(data));

  if (input.uploadFiles) {
    input.uploadFiles.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });
  }

  const response = await axios.post(`paymentMethod/update/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.success;
};

const updatePriorityAvailability = async input => {
  const data = input.map((paymentMethod, index) => {
    return {
      id: paymentMethod.id,
      priority: index,
      is_available: paymentMethod.isAvailable,
    };
  });

  const response = await axios.post(`paymentMethod/updatePriorityAvailability`, data);
  const success = response.data.success;
  return success;
};

const deleteData = async id => {
  const response = await axios.post(`paymentMethod/delete/${id}`);
  return response.data.success;
};

export const PaymentApi = {
  list,
  create,
  update,
  details,
  updatePriorityAvailability,
  deleteData,
};
