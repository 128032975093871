import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Collapse,
  Grid2 as Grid,
  ListItemButton,
  ListItemText,
  TextField
} from "@mui/material";
import React, { useState } from "react";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "react-i18next";

export default function Configuration({ data, setData }) {
  const { t } = useTranslation();
  const [openConfiguration, setOpenConfiguration] = useState(false);
  const [value, setValue] = React.useState(null);

  const handleOpenConfiguration = () => {
    setOpenConfiguration(!openConfiguration);
  };

  return (
    <Box component={"div"}>
      {/* Configuration */}
      <ListItemButton onClick={handleOpenConfiguration}>
        <ListItemText primary={t("promotion.configuration")} />
        {openConfiguration ? <RemoveIcon /> : <AddIcon />}
      </ListItemButton>
      <Collapse in={openConfiguration} timeout="auto" unmountOnExit>
        <Grid sx={{ p: 2 }} container spacing={2}>
          <Grid size={{
            xs: 12,
            md: 6
          }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label={t("layout.start_date")}
                onChange={newValue => {
                  if (!newValue) {
                    setData({
                      ...data,
                      startAt: null,
                    });
                    return;
                  }

                  setData({
                    ...data,
                    startAt: newValue.format("YYYY-MM-DD"),
                  });
                }}
                value={data.startAt}
                inputFormat="YYYY-MM-DD"
                renderInput={params => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
          </Grid>

          <Grid size={{
            xs: 12,
            md: 6
          }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label={t("layout.end_date")}
                onChange={newValue => {
                  if (!newValue) {
                    setData({
                      ...data,
                      endAt: null,
                    });
                    return;
                  }
                  setData({
                    ...data,
                    endAt: newValue.format("YYYY-MM-DD"),
                  });
                }}
                value={data.endAt}
                inputFormat="YYYY-MM-DD"
                renderInput={params => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
}
