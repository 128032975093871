import Cookies from "universal-cookie";
const AUTH_DATA_KEY = "pos_system_cms_auth";
const cookies = new Cookies(null, {
  maxAge: 1 * 24 * 60 * 60, // 1 days
  path: "/",
  // secure: process.env.NODE_ENV === "production",
});

const loginProgress = ({ user }) => {
  if (!user) {
    console.error("Missing user");
    return false;
  }

  const data = {
    ...user,
    // for protect user data and avoid user change the permission
    // do not store plain text locally, these will fetch from server and store in redux
    permissions: undefined,
    roles: undefined,
    email: undefined,
    username: undefined,
  };

  cookies.set(AUTH_DATA_KEY, JSON.stringify(data));

  return true;
};

const hasLogin = () => {
  return !!cookies.get(AUTH_DATA_KEY);
};

const resetAuthCookies = () => {
  cookies.set(AUTH_DATA_KEY, "", {
    maxAge: -1,
    path: "/",
  });
};

const getUserCookies = () => {
  const data = cookies.get(AUTH_DATA_KEY);
  return data ? data : null;
};

const getAccessTokenCookies = () => {
  const data = cookies.get(AUTH_DATA_KEY);
  return data ? data.jwt : null;
};

export const AuthHelper = {
  loginProgress,
  hasLogin,
  resetAuthCookies,
  getUserCookies,
  getAccessTokenCookies,
};
