import { Box, Card, CardContent, CardHeader, Grid2 as Grid, Skeleton } from "@mui/material";

export default function OrderSkeleton({ row, colSpan }) {
  return (
    <Grid container spacing={2}>
      <Grid size={{
        xs: 8,
      }}>
        <Box>
          <Card variant="outlined">
            <CardHeader
              subheader={<Skeleton height={50} width={"30%"} animation="wave" />}
            ></CardHeader>
            <CardContent>
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
            </CardContent>
          </Card>

          <Box sx={{ my: 4 }} />

          <Card variant="outlined">
            <CardHeader
              subheader={<Skeleton height={50} width={"30%"} animation="wave" />}
            ></CardHeader>
            <CardContent>
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
            </CardContent>
          </Card>

          <Box sx={{ my: 4 }} />

          <Card variant="outlined">
            <CardHeader
              subheader={<Skeleton height={50} width={"30%"} animation="wave" />}
            ></CardHeader>
            <CardContent>
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid size={{
        xs: 4,
      }}>
        <Box>
          <Card variant="outlined">
            <CardHeader
              subheader={<Skeleton height={50} width={"30%"} animation="wave" />}
            ></CardHeader>
            <CardContent>
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
              <Skeleton height={40} animation="wave" />
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
}
