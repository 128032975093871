import axios from "../helper/AxiosHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/category/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        coverImage: data.images && data.images.length > 0 ? data.images[0] : "",
        icon: data.icon,
        name: data.name,
        appliedStores: data.applied_stores || [],
        description: data.description,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const fullList = async () => {
  const response = await axios.get(`/category?fields=all`);
  const result = response.data.result;

  return result.map(data => {
    return {
      id: data.id,
      coverImage: data.images && data.images.length > 0 ? data.images[0] : "",
      name: data.name,
      appliedStores: data.applied_stores || [],
      description: data.description,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      createdBy: data.created_by_info ? data.created_by_info.username : "",
      updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
    };
  });
};

const details = async id => {
  const response = await axios.get(`category/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    name: result.name,
    description: result.description,
    images: result.images,
    appliedStores: result.applied_stores || [],
    priority: result.priority,
    showOnPos: result.show_on_pos,
    showOnOrdering: result.show_on_ordering,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    createdBy: result.created_by_info ? result.created_by_info.username : "",
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const create = async input => {
  const formData = new FormData();
  const data = {
    name: input.name,
    description: input.description,
    images: input.images || [],
    applied_stores: input.appliedStores ? input.appliedStores.map(store => store.id) : [],
    priority: input.priority,
    show_on_pos: input.showOnPos,
    show_on_ordering: input.showOnOrdering,
    upload_files: input.uploadFiles?.length || undefined,
  };

  formData.append("data", JSON.stringify(data));

  if (input.uploadFiles) {
    input.uploadFiles.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });
  }

  const response = await axios.post(`category/create`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data.result.insert_id;
};

const update = async (id, input) => {
  const formData = new FormData();
  const data = {
    name: input.name,
    description: input.description,
    images: input.images || [],
    applied_stores: input.appliedStores ? input.appliedStores.map(store => store.id) : [],
    priority: input.priority,
    show_on_pos: input.showOnPos,
    show_on_ordering: input.showOnOrdering,
    upload_files: input.uploadFiles || undefined,
  };

  formData.append("data", JSON.stringify(data));

  if (input.uploadFiles) {
    input.uploadFiles.forEach((file, index) => {
      formData.append(`file_${index + 1}`, file);
    });
  }

  const response = await axios.post(`category/update/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data.success;
};

const deleteData = async id => {
  const response = await axios.post(`category/delete/${id}`);
  return response.data.success;
};

export const CategoryApi = {
  list,
  details,
  create,
  update,
  deleteData,
  fullList
};
