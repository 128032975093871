import {
  Box,
  Collapse,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { useEffect, useState } from "react";

// icons
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// api
import { useTranslation } from "react-i18next";

export default function OrderDiscount({ data, setData }) {
  const { t } = useTranslation();

  const [openConditions, setOpenConditions] = useState(true);

  const handleOpenConditions = () => {
    setOpenConditions(!openConditions);
  };

  useEffect(() => {
    setData({
      ...data,
      parameter: {
        amount: data.orderDiscount.amount,
        type: data.orderDiscount.type,
        value: data.orderDiscount.value,
      },
    });
  }, [data.orderDiscount]);

  return (
    <Box component={"div"}>
      {/* Conditions */}
      <ListItemButton onClick={handleOpenConditions}>
        <ListItemText primary={t("promotion.condition")} />
        {openConditions ? <RemoveIcon /> : <AddIcon />}
      </ListItemButton>
      <Collapse in={openConditions} timeout="auto" unmountOnExit>
        <Box px={2} py={1}>
          <TextField
            value={data.orderDiscount.amount || 0}
            onChange={e => {
              setData({
                ...data,
                orderDiscount: {
                  ...data.orderDiscount,
                  amount: e.target.value,
                },
              });
            }}
            fullWidth
            label={t("order.amount")}
            type="number"
          />
        </Box>

        <Box px={2} py={1}>
          <Select
            fullWidth
            value={data.orderDiscount.type}
            onChange={event => {
              setData({
                ...data,
                orderDiscount: {
                  ...data.orderDiscount,
                  type: event.target.value,
                },
              });
            }}
          >
            <MenuItem value={"percent"}>{t("product.percent")}</MenuItem>
            <MenuItem value={"fixed"}>{t("product.fix")}</MenuItem>
          </Select>
        </Box>
        <Box px={2} py={1}>
          <TextField
            value={data.orderDiscount.value}
            onChange={e => {
              setData({
                ...data,
                orderDiscount: {
                  ...data.orderDiscount,
                  value: e.target.value,
                },
              });
            }}
            fullWidth
            label={t("layout.discount_price")}
            type="number"
          />
        </Box>
      </Collapse>
    </Box>
  );
}
