import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

// helper
import { FormatHelper } from "../../helper/FormatHelper";

// components
import DebugLogger from "../DebugLogger";
import PaymentMethodCard from "../pos/checkout/PaymentMethodCard";

// api
import { PaymentApi } from "../../api/PaymentApi";

export default function UpdatePaymentDialog({ onClose, open, data, onUpdate }) {
  const { t } = useTranslation();
  const [selectedPayments, setSelectedPayments] = useState(data.payments);
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [unpaid, setUnpaid] = useState(0);

  useEffect(() => {}, [open]);

  useEffect(() => {
    fetchData();
    setSelectedPayments(data.payments);
  }, []);

  useEffect(() => {
    let paid = 0;
    selectedPayments.forEach(p => {
      paid += p.amount;
    });
    setUnpaid(data.finalTotal - paid);
  }, [selectedPayments]);

  const handleClose = () => {
    onClose(null);
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const page = 1;
      const pageSize = -1;
      const response = await PaymentApi.list(page, pageSize, []);
      setPaymentMethodList(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addPayment = payment => {
    if (!payment.amount) {
      toast.error("Please enter the price");
      return;
    }

    if (selectedPayments.length >= 3) {
      toast.error("Maximum payment method");
      return;
    }

    let exists = false;

    selectedPayments.forEach(p => {
      if (p.code == payment.code) {
        exists = true;
      }
    });

    if (exists) {
      toast.error("This payment method is already exists");
      return;
    }

    const newPayment = [...selectedPayments];
    newPayment.push(payment);
    setSelectedPayments(newPayment);
  };

  const deletePayment = index => {
    const newPayment = [...selectedPayments];
    newPayment.splice(index, 1);
    setSelectedPayments(newPayment);
  };

  return (
    <Box>
      {open && (
        <Dialog onClose={handleClose} open={open} scroll={"body"}>
          <Card variant="outlined" sx={{ width: 600 }}>
            <CardHeader title={t("order.update_payment")} />
            {isLoading && <LinearProgress />}
            <Divider />

            <CardContent sx={{ height: 500, overflow: "auto" }}>
              <DebugLogger
                title="selectedPayments"
                data={selectedPayments}
                hidden={true}
              ></DebugLogger>
              {selectedPayments && selectedPayments.length > 0 && (
                <Card variant="outlined" sx={{ m: 1 }}>
                  <List disablePadding>
                    {selectedPayments.map((payment, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>{payment.name}</Box>
                        <Box display={"flex"} alignItems={"center"}>
                          {FormatHelper.formatAmount(payment.amount)}
                          <IconButton
                            onClick={() => {
                              deletePayment(index);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </Card>
              )}

              <Box
                sx={{
                  flex: 1,
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
                  gap: 2,
                  p: 1,
                  overflow: "auto",
                  alignContent: "flex-start",
                }}
              >
                {paymentMethodList.map((payment, index) => (
                  <PaymentMethodCard
                    key={index}
                    unpaid={unpaid}
                    payment={payment}
                    addPayment={addPayment}
                  />
                ))}
              </Box>
            </CardContent>

            <Divider />

            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button
                onClick={() => {
                  handleClose();
                }}
              >
                {t("layout.cancel")}
              </Button>
              <Button
                onClick={() => {
                  onUpdate(selectedPayments);
                }}
              >
                {t("layout.ok")}
              </Button>
            </CardActions>
          </Card>
        </Dialog>
      )}
    </Box>
  );
}
