import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid2 as Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// icons
import MoreVertIcon from "@mui/icons-material/MoreVert";

// components
import ReactSwal from "../../helper/AlertHelper";
import DebugLogger from "../DebugLogger";
import UpdaterInfo from "../UpdaterInfo";

// api
import { CategoryApi } from "../../api/CategoryApi";
import { InventoryApi } from "../../api/InventoryApi";
import { SupplierApi } from "../../api/SupplierApi";

export default function BaseInfo({
  data,
  setData,
  isEditing,
  deleteData,
  haveDeletePermission,
}) {
  const { t } = useTranslation();
  const [categoryList, setCategoryList] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    fetchCategory();
    fetchInventory();
    fetchSuppliers();
  }, []);

  const fetchCategory = async () => {
    const response = await CategoryApi.fullList(1, -1, []);
    setCategoryList(response);
  };

  const fetchInventory = async () => {
    const response = await InventoryApi.list(1, -1, []);
    setInventoryList(response.data);
  };

  const fetchSuppliers = async () => {
    const response = await SupplierApi.list(1, -1, []);
    setSupplierList(response.data);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        deleteData(data.id);
      }
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={t("layout.general")}
        action={
          deleteData &&
          haveDeletePermission && (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={showDeleteConfirm}>
                  {<Typography color={"error"}>{t("layout.delete")}</Typography>}
                </MenuItem>
              </Menu>
            </Box>
          )
        }
      />

      <CardContent>
        <DebugLogger title={"Product"} data={data} hidden={true} />

        <Grid container spacing={2}>
          <Grid size={{
            xs: 12,
            lg: 6,
          }}>
            <TextField
              value={data.title}
              onChange={event => setData({ ...data, title: event.target.value })}
              required
              fullWidth
              label={t("layout.name")}
            />
          </Grid>

          <Grid size={{
            xs: 6,
            lg: 3,
          }}>
            <RadioGroup
              row
              defaultValue={true}
              value={data.isAvailable || false}
              onChange={event => {
                setData({
                  ...data,
                  isAvailable: event.target.value == "true" ? true : false,
                });
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label={t("product.available")} />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={t("product.unavailable")}
              />
            </RadioGroup>
          </Grid>
        </Grid>

        <Stack spacing={2} sx={{
          py:2
        }}>
          <Autocomplete
            multiple
            options={categoryList}
            getOptionLabel={option => {
              return option.name;
            }}
            value={data.categories}
            filterSelectedOptions
            isOptionEqualToValue={(opt, value) => {
              return opt.id === value.id;
            }}
            onChange={(event, newValue) => {
              setData({
                ...data,
                categories: newValue.map(value => {
                  return value;
                }),
              });
            }}
            renderInput={params => (
              <TextField required {...params} label={t("category.category")} />
            )}
          />

          <Autocomplete
            options={supplierList}
            getOptionLabel={option => {
              return option.name;
            }}
            value={data.supplier}
            filterSelectedOptions
            isOptionEqualToValue={(opt, value) => {
              return opt.id === value.id;
            }}
            onChange={(event, newValue) => {
              console.log("supplier: ", newValue);
              setData({
                ...data,
                supplier: newValue,
              });
            }}
            renderInput={params => <TextField {...params} label={t("menu.suppliers")} />}
          />

          <Autocomplete
            multiple
            options={inventoryList}
            getOptionLabel={option => {
              return option.name;
            }}
            value={data.inventories || []}
            filterSelectedOptions
            isOptionEqualToValue={(opt, value) => {
              return opt.id === value.id;
            }}
            onChange={(event, newValue) => {
              setData({
                ...data,
                inventories: newValue.map(value => {
                  return value;
                }),
              });
            }}
            renderInput={params => (
              <TextField required {...params} label={t("layout.inventory")} />
            )}
          />

          <TextField
            fullWidth
            label={t("layout.description")}
            multiline
            value={data.description}
            onChange={event => {
              setData({ ...data, description: event.target.value });
            }}
            rows={4}
          />

          {!isEditing && (
            <RadioGroup
              row
              defaultValue={true}
              value={data.isSimple}
              onChange={event => {
                setData({
                  ...data,
                  options: [], // reset options
                  variants: [], // reset variants
                  isSimple: event.target.value == "true",
                });
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={t("product.simple_product")}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={t("product.product_with_variants")}
              />
            </RadioGroup>
          )}

          <Box>
            <FormControlLabel
              label={t("product.track_inventory")}
              control={
                <Checkbox
                  checked={data.trackInventory}
                  onChange={event => {
                    setData({
                      ...data,
                      trackInventory: event.target.checked,
                    });
                  }}
                />
              }
            />
            {/* <FormControlLabel
              label={t("product.print_kitchen_receipt")}
              control={
                <Checkbox
                  checked={data.printKitchenReceipt}
                  onChange={event => {
                    setData({
                      ...data,
                      printKitchenReceipt: event.target.checked,
                    });
                  }}
                />
              }
            /> */}
          </Box>

          <UpdaterInfo data={data} />
        </Stack>
      </CardContent>
    </Card>
  );
}
