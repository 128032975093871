import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Skeleton,
  Box,
  Grid,
} from "@mui/material";

export default function DasgboardSkeleton() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardHeader
            title={<Skeleton height={50} width={"100%"} animation="wave" />}
          ></CardHeader>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Skeleton
                  height={100}
                  width={"100%"}
                  variant="rectangular"
                  animation="wave"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardHeader
            title={<Skeleton height={50} width={"100%"} animation="wave" />}
          ></CardHeader>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Skeleton
                  height={100}
                  width={"100%"}
                  variant="rectangular"
                  animation="wave"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardHeader
            title={<Skeleton height={50} width={"100%"} animation="wave" />}
          ></CardHeader>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Skeleton
                  height={100}
                  width={"100%"}
                  variant="rectangular"
                  animation="wave"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardHeader
            title={<Skeleton height={50} width={"100%"} animation="wave" />}
          ></CardHeader>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Skeleton
                  height={100}
                  width={"100%"}
                  variant="rectangular"
                  animation="wave"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardHeader
            title={<Skeleton height={50} width={"100%"} animation="wave" />}
          ></CardHeader>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Skeleton
                  height={100}
                  width={"100%"}
                  variant="rectangular"
                  animation="wave"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card variant="outlined">
          <CardHeader
            title={<Skeleton height={50} width={"100%"} animation="wave" />}
          ></CardHeader>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Skeleton
                  height={100}
                  width={"100%"}
                  variant="rectangular"
                  animation="wave"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
