import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  Slide,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FullScreenLoading from "../FullScreenLoading";
import BarcodeList from "./BarcodeList";
import QrcodeList from "./QrcodeList";
import { useReactToPrint } from "react-to-print";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GenerateBarcodeDialog({ data }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [tab, setTab] = useState("barcode");
  const [quantity, setQuantity] = useState(1);
  const [startWith, setStartWith] = useState(1);
  const componentToPrint = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handlePrint = useReactToPrint({
    contentRef: componentToPrint,
  });

  return (
    <Box component={"div"}>
      <FullScreenLoading open={fullScreenLoading} />
      <Button color="inherit" variant="outlined" startIcon={<AddIcon />} onClick={handleClickOpen}>
        {t("product.generate_barcode")}
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar sx={{ my: 2 }}>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t("product.generate_barcode")}
            </Typography>
            <TextField
              label={t("product.start_with")}
              type="number"
              value={startWith || 1}
              onChange={event => {
                setStartWith(parseInt(event.target.value));
              }}
            />
            <TextField
              sx={{ px: 1 }}
              label={t("shopping_cart.qty")}
              type="number"
              value={quantity || 0}
              onChange={event => {
                setQuantity(parseInt(event.target.value));
              }}
            />
            <Button autoFocus color="inherit" onClick={handlePrint}>
              {t("product.print")}
            </Button>
          </Toolbar>
        </AppBar>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs centered value={tab} onChange={handleTabChange}>
            <Tab label={t("product.barcode")} value={"barcode"} />
            <Tab label={t("product.qrcode")} value={"qrcode"} />
          </Tabs>
        </Box>
        <Box py={1}></Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Box sx={{ width: "210mm" }}>
            <Box display={"flex"} justifyContent={"center"} ref={componentToPrint}>
              {tab == "barcode" && (
                <BarcodeList data={data} quantity={quantity} startWith={startWith} />
              )}
              {tab == "qrcode" && (
                <QrcodeList data={data} quantity={quantity} startWith={startWith} />
              )}
            </Box>
          </Box>
        </Box>
        <Box py={8}></Box>
      </Dialog>
    </Box>
  );
}
