import _axios from "axios";
import { Translation } from "react-i18next";

// helper
import ReactSwal from "./AlertHelper";
import { AuthHelper } from "./AuthHelper";

const axios = () => {
  const token = AuthHelper.getAccessTokenCookies();
  const instance = _axios.create({
    baseURL: process.env.REACT_APP_API_SERVER,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    timeout: 2 * 60 * 100,
  });

  instance.interceptors.response.use(
    function (response) {
      if (!response.data.success) {
        ReactSwal.fire({
          title: <Translation>{t => <>{t("error.title")}</>}</Translation>,
          html: response.data.message || "Unknown error",
        });
        return Promise.reject(response.data.message || "Unknown error");
      }
      return response;
    },
    function (error) {
      const statusCode = error.response.status;

      if (statusCode == 404) {
        ReactSwal.fire({
          title: <Translation>{t => <>{t("error.title")}</>}</Translation>,
          html: "404 Not Found",
        });
        return Promise.reject(error);
      }

      if (statusCode == 401) {
        // no permission or token expired
        AuthHelper.resetAuthCookies();
        ReactSwal.fire({
          title: <Translation>{t => <>{t("error.title")}</>}</Translation>,
          html: error.response.data.error || "Expired",
          allowOutsideClick: false,
        }).then(result => {
          /* Read more about isConfirmed, isDenied below */

          // on AuthProvider handled redirect
          // if (result.isConfirmed) {
          //   window.location.href = `/login`;
          // }
        });

        return Promise.reject(error);
      }

      if (error.response.data) {
        ReactSwal.fire({
          title: <Translation>{t => <>{t("error.title")}</>}</Translation>,
          html: error.response.data.error,
        });
      } else {
        ReactSwal.fire({
          title: <Translation>{t => <>{t("error.title")}</>}</Translation>,
          html: error.message || "Unknown error",
        });
      }

      return Promise.reject(error);
    },
  );

  return instance;
};

export default axios();
