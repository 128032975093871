import { Breadcrumbs, Container, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import BaseInfo from "../../components/member/BaseInfo";

// api
import { MemberApi } from "../../api/MemberApi";

// validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(
      <Translation>{t => t("error.email_is_required")}</Translation>,
    )
    .email(
      <Translation>{t => t("error.invalid_email_format")}</Translation>
    ),
  phoneNumber: Yup.string()
    .min(8, <Translation>{t => t("error.phone_number_min_length")}</Translation>)
    .matches(/^[0-9]*$/, <Translation>{t => t("error.phone_number_is_invalid")}</Translation>),
});

export default function Create({ }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      remark: "",
      photo: "",
    },
    validateOnMount: true,
    validationSchema: validationSchema,
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  const onSubmit = async () => {
    try {
      formik.setTouched({
        email: true,
        phoneNumber: true,
      });

      formik.validateForm();

      if (!formik.isValid) {
        return;
      }

      const data = {
        ...formik.values,
      };

      setFullScreenLoading(true);

      const newMember = await MemberApi.create(data);
      setFullScreenLoading(false);

      if (newMember) {
        toast.success(t("layout.created_successfully"));
        navigate("/members");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/members"}>{t("menu.members")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <BaseInfo
        formik={formik}
      />
      <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
    </Container>
  );
}
