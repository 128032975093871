import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function FilterButton({ searchStr, setFilter, storeList, categoryList }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [category, setCategory] = useState("all");
  const [status, setStatus] = useState("all");
  const [statusList, setStatusList] = useState([]);
  const [store, setStore] = useState("all");

  useEffect(() => {
    setStatusList([
      {
        name: "可用",
        value: 1,
      },
      {
        name: "不可用",
        value: 0,
      },
    ]);
  }, []);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeCategory = event => {
    console.log("value", event.target.value);
    setCategory(event.target.value);
  };

  const onChangeStatus = event => {
    console.log("value", event.target.value);
    setStatus(event.target.value);
  };

  const onChangeStore = event => {
    console.log("value", event.target.value);
    setStore(event.target.value);
  };

  const onChangeFilter = () => {
    let filters = [];

    if (searchStr) {
      filters.push({ field: "title", value: searchStr });
    }

    if (category !== "all") {
      filters.push({ field: "categories", value: category, op: "contain" });
    }

    if (status !== "all") {
      filters.push({ field: "is_available", value: status === 1 ? "true" : "false"});
    }

    if (store != "all") {
      filters.push({ field: "inventories", value: store.inventoryId, op: "contain", id: store.id});
    }

    if (!filters.length) {
      filters.push({ field: "title", value: "" });
    }

    setFilter(filters);
    handleClose();
  };

  const clearFilter = () => {
    setCategory("all");
    setStatus("all");
    setStore("all");
    setFilter([{ field: "title", value: "" }]);
    handleClose();
  };

  return (
    <Box>
      <Button
        sx={{ p: 1, mx: 2 }}
        variant="outlined"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {t("layout.filter")}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box sx={{ p: 2, width: 600 }}>
          <Typography variant="h6">{t("layout.filter")}</Typography>
          <Divider sx={{
            my:2
          }}/>
            <Stack spacing={2}>
         
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("category.category")}</InputLabel>
                <Select
                  label={t("category.category")}
                  value={category}
                  fullWidth
                  onChange={onChangeCategory}
                >
                  <MenuItem value={"all"}>{t("layout.all")}</MenuItem>
                  {categoryList.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
    
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label2">{t("layout.status")}</InputLabel>

                <Select
                  label={t("layout.status")}
                  value={status}
                  fullWidth
                  onChange={onChangeStatus}
                >
                  <MenuItem value={"all"}>{t("layout.all")}</MenuItem>
                  {statusList.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
    
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("menu.stores")}</InputLabel>

                <Select
                  label={t("menu.stores")}
                  value={store}
                  fullWidth
                  onChange={onChangeStore}
                >
                  <MenuItem value={"all"}>{t("layout.all")}</MenuItem>
                  {storeList.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
              


          <Divider sx={{ mb: 2 }} />
          <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
            <Button
              variant="outlined"
              onClick={() => {
                clearFilter();
              }}
            >
              {t("layout.clear_filter")}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onChangeFilter();
              }}
            >
              {t("layout.ok")}
            </Button>
          </Box>
        </Box>
      </Menu>
    </Box>
  );
}
