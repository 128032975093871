import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  ButtonBase,
  Card,
  Collapse,
  Grid2 as Grid,
  ListItemButton,
  ListItemText,
  Radio,
  Typography
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getBuyItemXGetDiscountYPromotionType,
  getBuyXPayYPromotionType,
} from "../../helper/GlobalValueHelper";

export default function Type({ data, setData }) {
  const { t } = useTranslation();
  const [openType, setOpenType] = useState(true);

  const handleChange = value => {
    setData({ ...data, logicType: value });
  };

  const handleOpenType = () => {
    setOpenType(!openType);
  };

  return (
    <Box component={"div"}>
      {/* Discount type */}
      <ListItemButton onClick={handleOpenType}>
        <ListItemText primary={t("layout.type")} />
        {openType ? <RemoveIcon /> : <AddIcon />}
      </ListItemButton>
      <Collapse in={openType} timeout="auto" unmountOnExit>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid size={{
              xs: 12,
              md: 6
            }} justifyContent={"center"}>
              <ButtonBase sx={{ width: "100%" }}>
                <Card
                  variant="outlined"
                  sx={{ display: "flex", p: 2, width: "100%" }}
                  onClick={event => {
                    handleChange(getBuyXPayYPromotionType());
                  }}
                >
                  <Radio
                    checked={data.logicType === getBuyXPayYPromotionType()}
                    onChange={handleChange}
                  />
                  <Box>
                    <Typography variant="h6" sx={{ textAlign: "left" }}>
                      {t("promotion.bundle")}
                    </Typography>
                    <Typography variant="body2" sx={{ textAlign: "left" }}>
                      {t("promotion.buy_x_pay_y")}
                    </Typography>
                  </Box>
                </Card>
              </ButtonBase>
            </Grid>

            <Grid size={{
              xs: 12,
              md: 6
            }} justifyContent={"center"}>
              <ButtonBase sx={{ width: "100%" }}>
                <Card
                  variant="outlined"
                  sx={{ display: "flex", p: 2, width: "100%" }}
                  onClick={event => {
                    handleChange(getBuyItemXGetDiscountYPromotionType());
                  }}
                >
                  <Radio checked={data.logicType === getBuyItemXGetDiscountYPromotionType()} />
                  <Box>
                    <Typography variant="h6" sx={{ textAlign: "left" }}>
                      {t("promotion.discount_on_item")}
                    </Typography>
                    <Typography variant="body2" sx={{ textAlign: "left" }}>
                      {t("promotion.buy_x_get_discount_y")}
                    </Typography>
                  </Box>
                </Card>
              </ButtonBase>
            </Grid>
            <Grid size={{
              xs: 12,
              md: 6
            }} justifyContent={"center"}>
              <ButtonBase sx={{ width: "100%" }}>
                <Card
                  variant="outlined"
                  sx={{ display: "flex", p: 2, width: "100%" }}
                  onClick={event => {
                    handleChange("buy_amount_get_discount_y");
                  }}
                >
                  <Radio checked={data.logicType === "buy_amount_get_discount_y"} />
                  <Box>
                    <Typography variant="h6" sx={{ textAlign: "left" }}>
                      {t("promotion.discount_on_order")}
                    </Typography>
                    <Typography variant="body2" sx={{ textAlign: "left" }}>
                      {t("promotion.buy_x_get_discount_y")}
                    </Typography>
                  </Box>
                </Card>
              </ButtonBase>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
}
