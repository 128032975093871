import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getStorePageName } from "../helper/GlobalValueHelper";
import Create from "../views/store/Create";
import Edit from "../views/store/Edit";
import List from "../views/store/List";

const StoreRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/stores",
      element: (
        <AuthProvider page={getStorePageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "/stores/:id",
      element: (
        <AuthProvider page={getStorePageName()} action={"update"}>
          <Edit />
        </AuthProvider>
      ),
    },
    {
      path: "/stores/create",
      element: (
        <AuthProvider page={getStorePageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
  ],
};

export default StoreRoute;
