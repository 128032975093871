import { Box } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";

export default function MetaHeader({ children }) {
  useEffect(() => {
    document.title = "Finger Work Pos";
  }, []);

  return (
    <Box>
      {children}
    </Box>
  );
}
