import axios from "../helper/AxiosHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/order/query?query=${JSON.stringify(query)}`);
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        orderNum: data.order_num,
        memberId: data.member_id,
        customerName: data.customer_name,
        storeId: data.store_id,
        originTotal: data.origin_total,
        finalTotal: data.final_total,
        paidTotal: data.paid_total,
        change: data.change,
        status: data.status,
        payStatus: data.pay_status,
        shipStatus: data.ship_status,
        remark: data.remark,
        saledBy: data.saled_by,
        orderedAt: data.ordered_at,
        createdBy: data.created_by,
        updatedAt: data.updated_at,
        member: data.member,
        saled: data.saled,
        storeName: data.store_id_info ? data.store_id_info.name : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`order/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    status: result.status,
    orderNum: result.order_num,
    customerName: result.customer_name,
    memberId: result.member_id,
    storeId: result.store_id,
    originTotal: result.origin_total,
    finalTotal: result.final_total,
    paidTotal: result.paid_total,
    change: result.change,
    adjustment: result.adjustment,
    payStatus: result.pay_status,
    shipStatus: result.ship_status,
    remark: result.remark,
    saledBy: result.saled_by,
    createdBy: result.created_by,
    updatedAt: result.updated_at,
    createdAt: result.created_at,
    member: result.member
      ? {
          id: result.member.id,
          nameZh: result.member.name_zh,
          nameEn: result.member.name_en,
          email: result.member.email,
          phoneNumber: result.member.phone_number,
        }
      : null,
    saled: result.saled,
    items: result.order_items.map(variant => {
      const product = result.order_products.find(product => product.id == variant.product_id);
      return {
        id: variant.id,
        orderId: variant.order_id,
        productId: variant.product_id,
        productTitle: variant.product_title,
        variantId: variant.variant_id,
        sku: variant.sku,
        qty: variant.qty,
        shipQty: variant.ship_qty,
        shipStatus: variant.ship_status,
        barcode: variant.barcode,
        finalPrice: variant.final_price,
        originTotal: variant.origin_price,
        optionValues: variant.option_values,
        optionMapping: variant.option_values,
        promotion: variant.promotion,
        product: product,
        adjustment: variant.adjustment,
      };
    }),
    payments: result.order_payments
      ? result.order_payments.map(payment => {
          return {
            id: payment.id,
            code: payment.code,
            name: payment.name,
            amount: payment.amount,
          };
        })
      : [],
    promotions: result.promotions ? result.promotions : [],
  };
};

const refund = async id => {
  const response = await axios.post(`order/refund/${id}`);
  return response.data.success;
};

const cancel = async id => {
  const response = await axios.post(`order/cancel/${id}`);
  return response.data.success;
};

const updatePayment = async (id, input) => {
  const data = input.map(payment => {
    return {
      code: payment.code,
      amount: payment.amount,
    };
  });

  const response = await axios.post(`order/updatePayment/${id}`, data);
  return response.data.success;
};

const orderHistory = async orderNum => {
  const query = {
    filters: [{ field: "order_num", value: orderNum }],
    page: 1,
    page_size: 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/orderHistory/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        orderNum: data.order_num,
        createdBy: data.created_by_username,
        createdAt: data.created_at,
        event: data.event,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const batchUpdateItemShipQty = async orderItem => {
  const data =
    orderItem.map(item => {
      return {
        id: item.id,
        ship_qty: item.shipQty,
      };
    }) ?? [];

  const response = await axios.post(`orderItem/batchUpdateItemShipQty`, data);
  return response.data.success;
};

export const OrderApi = {
  list,
  details,
  updatePayment,
  refund,
  cancel,
  orderHistory,
  batchUpdateItemShipQty,
};
