import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// components
import ActionFooter from "../../../../components/ActionFooter";
import FullScreenLoading from "../../../../components/FullScreenLoading";
import OptionValueBaseInfo from "../../../../components/product/OptionValueBaseInfo";

// api
import { ProductApi } from "../../../../api/ProductApi";

export default function Create() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  const onSubmit = async () => {
    if (!data.name) {
      toast.error(t("error.please_enter_all_required_fields"));
      return;
    }

    const id = await ProductApi.createProductOptionValue(data);

    if (id) {
      toast.success(t("layout.created_successfully"));
      navigate(`/products/options/values`);
    }
  };

  const onCancel = () => {
    navigate("/products/options/values");
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />

      <Box component="form">
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
          <Link to={"/settings"}>{t("menu.settings")}</Link>
          <Link to={"/products/options/values"}>{t("product.option_values")}</Link>
          <Typography color="text.primary">{t("layout.create")}</Typography>
        </Breadcrumbs>
        <OptionValueBaseInfo data={data} setData={setData} />
        <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
