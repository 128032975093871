import axios from "../helper/AxiosHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/inventory/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        name: data.name,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`inventory/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    name: result.name,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    createdBy: result.created_by_info ? result.created_by_info.username : "",
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const create = async input => {
  const data = {
    name: input.name,
  };

  const response = await axios.post(`inventory/create`, data);

  return response.data.result.insert_id;
};

const update = async (id, input) => {
  const data = {
    name: input.name,
  };

  const response = await axios.post(`inventory/update/${id}`, data);
  return response.data.success;
};

const deleteData = async id => {
  const response = await axios.post(`inventory/delete/${id}`);
  return response.data.success;
};

const stockBulkChange = async item => {
  const data = {
    inventory_id: item.inventoryId,
    changes: item.changes.map(change => {
      return {
        action: change.action,
        variant_id: change.variantId,
        qty_change: change.qtyChange || 0,
        remark: change.remark || "",
      };
    }),
  };

  const response = await axios.post(`stock/bulkChange`, data);
  return response.data.success;
};

const stockQuery = async (inventoryId, page, pageSize, filters) => {
  const query = {
    inventory_id: inventoryId,
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.post(`/stock/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;

  return {
    data: result.map(data => {
      return {
        id: data.id,
        title: data.title,
        description: data.description,
        categories: data.categories,
        options: data.options,
        variants: data.variants.map(variant => {
          const optionMapping = variant.option_values;
          let optionValues = [];
          for (const [attrKey, attrValue] of Object.entries(optionMapping)) {
            if (attrValue && attrValue.id) {
              optionValues.push(attrValue.id);
            }
          }
          return {
            id: variant.id,
            productId: variant.product_id,
            sku: variant.sku,
            stock: variant.stock,
            cost: variant.cost,
            price: variant.price,
            barcode: variant.barcode,
            optionValues: optionValues,
            optionMapping: optionMapping,
          };
        }),
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const inventoryStockHistory = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.post(
    `/inventoryStockHistory/query?query=${JSON.stringify(query)}&fields=all`,
  );
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        stockTime: data.stock_time,
        variantId: data.variant_id,
        action: data.action,
        qtyChange: data.qty_change,
        remainingStock: data.remaining_stock,
        remark: data.remark,
        variant: data.variant
          ? {
              productName: data.variant.product_name,
              optionValues: data.variant.option_values,
              sku: data.variant.sku,
            }
          : null,
        createdAt: data.created_at,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const productStock = async (storeId, variants) => {
  const response = await axios.get(
    `/stock/productStock?store_id=${storeId}&variants=${JSON.stringify(variants)}`,
  );
  const result = response.data.result;

  return result.map(data => {
    return {
      variantId: data.variant_id,
      stock: data.stock,
    };
  });
};

export const InventoryApi = {
  list,
  details,
  create,
  update,
  deleteData,
  stockBulkChange,
  stockQuery,
  inventoryStockHistory,
  productStock,
};
