import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Link,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

// helper
import { FormatHelper } from "../../helper/FormatHelper";

// icons
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// components
import DebugLogger from "../DebugLogger";
import TableSkeleton from "../TableSkeleton";

// api
import { OrderApi } from "../../api/OrderApi";

export default function OrderHistory({ memberId }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState([{ field: "member_id", value: memberId }]);
  const [searchStr, setSearchStr] = useState();
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  const [colSpan, setColSpan] = useState(4);

  useEffect(() => {
    fetchHistoryList();
  }, [page]);

  const onChangePage = value => {
    setPage(value);
    // setSearchParams({ page: value });
  };

  const fetchHistoryList = async () => {
    setIsLoading(true);
    const response = await OrderApi.list(page, pageSize, filter);
    setList(response.data);
    setTotal(response.total);
    setIsLoading(false);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 5,
      }}
    >
      <CardHeader title={t("member.orders_history")} />

      <CardContent>
        <DebugLogger title="Member Orders History" data={list} hidden={true}></DebugLogger>
        <TableContainer elevation={0} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box variant="caption">{t("layout.id")}</Box>
                </TableCell>
                <TableCell>
                  <Box variant="caption">{t("layout.date")}</Box>
                </TableCell>
                <TableCell>
                  <Box variant="caption">{t("order.amount")}</Box>
                </TableCell>
                <TableCell>
                  <Box variant="caption">{t("order.payment_status")}</Box>
                </TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableSkeleton row={pageSize} colSpan={colSpan} />
            ) : (
              <TableBody>
                {!list.length && (
                  <TableRow>
                    <TableCell colSpan={colSpan} align="center">
                      {t("layout.no_data")}
                    </TableCell>
                  </TableRow>
                )}
                {list.map(row => (
                  <TableRow
                    hover
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Link href={`/orders/${row.id}`} target={"_blank"}>
                        <Box display={"flex"} alignItems={"center"}>
                          {row.orderNum}
                          <OpenInNewIcon sx={{ fontSize: 10 }} />
                        </Box>
                      </Link>
                    </TableCell>
                    <TableCell>{row.updatedAt}</TableCell>
                    <TableCell>{FormatHelper.formatAmount(row.finalTotal)}</TableCell>
                    <TableCell>
                      <Chip
                        variant="outlined"
                        size="small"
                        color={row.payStatus ? "success" : "error"}
                        label={row.payStatus ? t("order.paid") : t("order.unpaid")}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "space-between", px: 2 }}>
          <Typography variant="body2">
            {t("layout.total")}: {total}
          </Typography>
          <Stack spacing={2}>
            <Pagination
              page={page}
              count={Math.ceil(total / pageSize)}
              shape="rounded"
              onChange={(event, value) => {
                onChangePage(value);
              }}
            />
          </Stack>
        </Box>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
