import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import {
  getProductOptionPageName,
  getProductOptionValuePageName,
  getProductPageName,
} from "../helper/GlobalValueHelper";
import ProductCreate from "../views/product/Create";
import ProductEdit from "../views/product/Edit";
import ProductList from "../views/product/List";
import ProductBarcodeList from "../views/product/barcode/List";
import OptionCreate from "../views/product/option/Create";
import OptionEdit from "../views/product/option/Edit";
import OptionList from "../views/product/option/List";
import ProductOptionValueCreate from "../views/product/option/value/Create";
import ProductOptionValueEdit from "../views/product/option/value/Edit";
import ProductOptionValueList from "../views/product/option/value/List";

const ProductRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/products/options/values",
      element: (
        <AuthProvider page={getProductOptionValuePageName()} action={"view"}>
          <ProductOptionValueList />
        </AuthProvider>
      ),
    },
    {
      path: "/products/options/values/create",
      element: (
        <AuthProvider page={getProductOptionValuePageName()} action={"create"}>
          <ProductOptionValueCreate />
        </AuthProvider>
      ),
    },
    {
      path: "/products/options/values/:id",
      element: (
        <AuthProvider page={getProductOptionValuePageName()} action={"update"}>
          <ProductOptionValueEdit />
        </AuthProvider>
      ),
    },
    {
      path: "/products/options",
      element: (
        <AuthProvider page={getProductOptionPageName()} action={"view"}>
          <OptionList />
        </AuthProvider>
      ),
    },
    {
      path: "/products/options/create",
      element: (
        <AuthProvider page={getProductOptionPageName()} action={"create"}>
          <OptionCreate />
        </AuthProvider>
      ),
    },
    {
      path: "/products/options/:id",
      element: (
        <AuthProvider page={getProductOptionPageName()} action={"update"}>
          <OptionEdit />
        </AuthProvider>
      ),
    },
    {
      path: "/products",
      element: (
        <AuthProvider page={getProductPageName()} action={"view"}>
          <ProductList />
        </AuthProvider>
      ),
    },
    {
      path: "/products/:id",
      element: (
        <AuthProvider page={getProductPageName()} action={"update"}>
          <ProductEdit />
        </AuthProvider>
      ),
    },
    {
      path: "/products/create",
      element: (
        <AuthProvider page={getProductPageName()} action={"create"}>
          <ProductCreate />
        </AuthProvider>
      ),
    },
    {
      path: "/products/barcode",
      element: (
        <AuthProvider page={getProductPageName()} action={"create"}>
          <ProductBarcodeList />
        </AuthProvider>
      ),
    },
  ],
};

export default ProductRoute;
