import {
  Autocomplete,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// icons
import MoreVertIcon from "@mui/icons-material/MoreVert";

// components
import ReactSwal from "../../helper/AlertHelper";
import DebugLogger from "../DebugLogger";
import UpdaterInfo from "../UpdaterInfo";

// api
import { ProductApi } from "../../api/ProductApi";

// permission
import { useSelector } from "react-redux";
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

export default function OptionBaseInfo({ data, setData, isDisabled, deleteData }) {
  const { t } = useTranslation();
  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const [valueList, setValueList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const open = Boolean(anchorEl);

  useEffect(() => {
    fetchOptionValues();
  }, []);

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const fetchOptionValues = async () => {
    const response = await ProductApi.productOptionValueFullList();

    setValueList(response);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        deleteData();
      }
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={t("product.product_options")}
        subheader={<Typography variant="caption">{t("product.manage_product_options")}</Typography>}
        action={
          haveDeletePermission && (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={showDeleteConfirm}>
                  {<Typography color={"error"}>{t("layout.delete")}</Typography>}
                </MenuItem>
              </Menu>
            </Box>
          )
        }
      />

      <CardContent>

        <DebugLogger title="Product Option" data={data} hidden={true}></DebugLogger>

        <Stack spacing={2}>

          <TextField
            disabled={isDisabled}
            value={data.name}
            onChange={event => setData({ ...data, name: event.target.value })}
            required
            fullWidth
            autoFocus
            label={t("layout.name")}
          />

          <Autocomplete
            disabled={isDisabled}
            multiple
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            options={valueList}
            value={data.values}
            filterSelectedOptions
            disableCloseOnSelect
            getOptionLabel={opt => opt.name}
            inputValue={inputValue}
            onInputChange={(event, value, reason) => {
              if (event && event.type === "blur") {
                setInputValue("");
              } else if (reason !== "reset") {
                setInputValue(value);
              }
            }}
            onChange={(event, newValue) => {
              setData({
                ...data,
                values: newValue,
              });
            }}
            renderInput={params => <TextField required {...params} label={t("product.values")} />}
          />

          <UpdaterInfo data={data} />
        </Stack>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
