import { Card, CardActions, CardContent, CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

// api
import { OrderApi } from "../../api/OrderApi";

export default function OrderTimeline({ data }) {
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await OrderApi.orderHistory(data.orderNum);
      // console.log(response);
      setList(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(t("order.detail.error"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card variant="outlined" sx={{ height: "100%" }}>
      <CardHeader title={t("order.timeline")} />

      <CardContent>
        <Timeline>
          {list.map((item, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent sx={{ m: "auto 0" }} variant="body2" color="text.secondary">
                {item.createdAt}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="success" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  py: "12px",
                  px: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography>{t(`order.${item.event}`)}</Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
