import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid2 as Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactSwal from "../../helper/AlertHelper";
import DebugLogger from "../DebugLogger";

export default function BaseInfo({
  data,
  cancelOrder,
  refundOrder,
  haveRefundPermission,
  haveCancelPermission,
}) {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showRefundConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("order.refund_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        refundOrder();
      }
    });
  };

  const showCancelConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("order.cancel_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        cancelOrder();
      }
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={data.orderNum}
        subheader={<Typography variant="caption">{data.updatedAt}</Typography>}
        action={
          <Box display={"flex"} alignItems={"center"} height={50}>
            <Box display={"flex"} alignItems={"center"}>
              <FiberManualRecordIcon
                fontSize="8"
                color={data.status == "complete" ? "success" : "error"}
              />
              <Typography variant="caption">{t(`order.${data.status}`)}</Typography>
            </Box>
            {data.status != "refund" && data.status != "cancel" ? (
              <Box>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem disabled={!haveRefundPermission} onClick={showRefundConfirm}>
                    <Typography color={"error"}>{t("order.refund")}</Typography>
                  </MenuItem>
                  <MenuItem disabled={!haveCancelPermission} onClick={showCancelConfirm}>
                    <Typography color={"error"}>{t("order.cancel")}</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Box p={1}></Box>
            )}
          </Box>
        }
      />

      <CardContent>
        <DebugLogger title="Orders" data={data} hidden={true}></DebugLogger>

        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 4 }}>
            <Stack spacing={2}>
              <Typography variant="body2">{t("order.member_name")}</Typography>
              <Typography variant="body2">{data.customerName || "-"}</Typography>
            </Stack>
          </Grid>

          <Grid size={{ xs: 12, md: 4 }}>
            <Stack spacing={2}>
              <Typography variant="body2">{t("layout.email")}</Typography>
              <Typography variant="body2">{data.member ? data.member.email : "-"}</Typography>
            </Stack>
          </Grid>

          <Grid size={{ xs: 12, md: 4 }}>
            <Stack spacing={2}>
              <Typography variant="body2">{t("layout.phone_number")}</Typography>
              <Typography variant="body2">
                {data.member ? data.member.phoneNumber : "-"}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
