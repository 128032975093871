import { useRoutes } from "react-router-dom";
import HomeRoute from "./routes/HomeRoute";
import MemberRoute from "./routes/MemberRoute";
import CategoryRoute from "./routes/CategoryRoute";
import InventoryRoute from "./routes/InventoryRoute";
import OrderRoute from "./routes/OrderRoute";
import NotValidRoute from "./routes/NotValidRoute";
import PageNotFoundRoute from "./routes/PageNotFound";
import PermissionModuleRoute from "./routes/PermissionModuleRoute";
import PayemntRoute from "./routes/PaymentRoute";
import ProductRoute from "./routes/ProductRoute";
import SupplierRoute from "./routes/SupplierRoute";
import StoreRoute from "./routes/StoreRoute";
import RoleRoute from "./routes/RoleRoute";
import SettingsRoute from "./routes/SettingsRoute";
import UsersRoute from "./routes/UserRoute";
import PromotionRoute from "./routes/PromotionRoute";
import ReceiverEmailRoute from "./routes/ReceiverEmailRoute";

export default function Router() {
  const routes = useRoutes([
    HomeRoute,
    CategoryRoute,
    InventoryRoute,
    OrderRoute,
    PayemntRoute,
    ProductRoute,
    SupplierRoute,
    StoreRoute,
    RoleRoute,
    MemberRoute,
    NotValidRoute,
    PageNotFoundRoute,
    SettingsRoute,
    UsersRoute,
    PermissionModuleRoute,
    PromotionRoute,
    ReceiverEmailRoute,
  ]);

  return routes;
}
