import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// components
import ActionFooter from "../../components/ActionFooter";
import FullScreenLoading from "../../components/FullScreenLoading";
import BaseInfo from "../../components/receiverEmail/BaseInfo";

// api
import { ReceiverEmailApi } from "../../api/ReceiverEmailApi";

export default function Create() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: null,
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  const validateForm = () => {
    let hasError = false;
    if (!data.email || data.email == "") {
      toast.error(t("error.email_required"));
      hasError = true;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(data.email)) {
      toast.error(t("error.email_invalid"));
      hasError = true;
    }

    return hasError;
  };
  const onSubmit = async () => {
    let error = validateForm();

    if (error) {
      return;
    }

    let input = { ...data };

    try {
      setFullScreenLoading(true);
      const id = await ReceiverEmailApi.create(input);
      if (id) {
        toast.success(t("layout.created_successfully"));
        navigate(`/receiver-email`);
      }
      setFullScreenLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/settings"}>{t("menu.settings")}</Link>
        <Link to={"/receiver-email"}>{t("menu.receiver_email")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <BaseInfo data={data} setData={setData} />
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
