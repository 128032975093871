import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getInventoryPageName } from "../helper/GlobalValueHelper";
import Create from "../views/inventory/Create";
import Edit from "../views/inventory/Edit";
import List from "../views/inventory/List";
import Management from "../views/inventory/Management";

const InventoryRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/inventory",
      element: (
        <AuthProvider page={getInventoryPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "/inventory/:id",
      element: (
        <AuthProvider page={getInventoryPageName()} action={"update"}>
          <Edit />
        </AuthProvider>
      ),
    },
    {
      path: "/inventory/create",
      element: (
        <AuthProvider page={getInventoryPageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
    {
      path: "/inventory/management",
      element: (
        <AuthProvider page={getInventoryPageName()} action={"manage"}>
          <Management />
        </AuthProvider>
      ),
    },
  ],
};

export default InventoryRoute;
