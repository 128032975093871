import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// api
import { ProductApi } from "../../api/ProductApi";

export default function Simple({ data, setData, isEditing }) {
  const { t } = useTranslation();
  const [variant, setVariant] = useState({
    id: null,
    barcode: null,
    optionValues: [],
    price: 0,
    sku: null,
    isAvailable: true,
  });
  const [option, setOption] = useState({});

  useEffect(() => {
    fetchOptionList();
  }, []);

  useEffect(() => {
    setData({
      ...data,
      variants: [variant],
      options: [option.id],
    });
  }, [variant]);

  const fetchOptionList = async () => {
    const response = await ProductApi.productOptionFullList();
    // first value is the default option
    setOption(response[0]);
    if (data.variants[0]) {
      setVariant(data.variants[0]);
    } else {
      setVariant({ ...variant, optionValues: [response[0].id] });
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader title={t("layout.variants")} />

      <CardContent>
        <TableContainer elevation={0} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>{t("product.cost")}</TableCell>
                <TableCell>{t("product.barcode")}</TableCell>
                <TableCell>{t("product.price")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>
                  <TextField
                    variant="outlined"
                    value={variant.sku || ""}
                    onChange={event => {
                      setVariant({ ...variant, sku: event.target.value });
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    type={"number"}
                    inputProps={{ min: 0 }}
                    value={variant.cost || 0}
                    onChange={event => {
                      setVariant({ ...variant, cost: event.target.value });
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    value={variant.barcode || ""}
                    onChange={event => {
                      setVariant({ ...variant, barcode: event.target.value });
                    }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    type={"number"}
                    inputProps={{ min: 0 }}
                    value={variant.price || 0}
                    onChange={event => {
                      setVariant({ ...variant, price: event.target.value });
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
