import axios from "../helper/AxiosHelper";
import { storeTypeList } from "../helper/GlobalValueHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
    sort: "id.DESC",
  };
  const response = await axios.get(`/store/query?query=${JSON.stringify(query)}&fields=all`);
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        name: data.name,
        address: data.address,
        phoneNumber: data.phone_number,
        inventoryId: data.inventory_id,
        type: storeTypeList.find(x => x.value === data.type) || null,
        createdAt: data.created_at,
        updatedAt: data.updated_at,
        createdBy: data.created_by_info ? data.created_by_info.username : "",
        updatedBy: data.updated_by_info ? data.updated_by_info.username : "",
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`store/show/${id}?fields=all`);
  const result = response.data.result;
  return {
    id: result.id,
    name: result.name,
    address: result.address,
    phoneNumber: result.phone_number,
    inventoryId: result.inventory_id,
    code: result.code,
    type: storeTypeList.find(x => x.value === result.type) || null,
    createdAt: result.created_at,
    updatedAt: result.updated_at,
    createdBy: result.created_by_info ? result.created_by_info.username : "",
    updatedBy: result.updated_by_info ? result.updated_by_info.username : "",
  };
};

const create = async input => {
  const data = {
    name: input.name,
    address: input.address,
    phone_number: input.phoneNumber,
    inventory_id: input.inventoryId,
    code: input.code,
    // type: input.type.value || undefined,
  };

  const response = await axios.post(`store/create`, data);

  return response.data.result.insert_id;
};

const update = async (id, input) => {
  const data = {
    id: input.id,
    name: input.name,
    address: input.address,
    phone_number: input.phoneNumber,
    inventory_id: input.inventoryId,
    code: input.code,
    // type: input.type.value || undefined,
  };

  const response = await axios.post(`store/update/${id}`, data);
  return response.data.success;
};

const deleteData = async id => {
  const response = await axios.post(`store/delete/${id}`);
  return response.data.success;
};

export const StoreApi = {
  list,
  details,
  create,
  update,
  deleteData,
};
