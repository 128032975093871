import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Breadcrumbs,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Container,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ProductApi } from "../../../api/ProductApi";
import GenerateBarcodeDialog from "../../../components/product/GenerateBarcodeDialog";
import TableSkeleton from "../../../components/TableSkeleton";

export default function List() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState();
  const [searchStr, setSearchStr] = useState();
  const [total, setTotal] = useState(0);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    if (searchParams.get("page")) {
      setPage(parseInt(searchParams.get("page")));
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    if (!filter || filter.length <= 0) return;
    setPage(1);
    fetchData();
  }, [filter]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await ProductApi.list(page, pageSize, filter);
      setProducts(response.data);
      setTotal(response.total);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const select = (data) => {
    if (selectedIds.includes(data.id)) {
      setSelectedIds(selectedIds.filter((item) => item != data.id));
      setSelectedProducts(
        selectedProducts.filter((item) => item.id != data.id)
      );
      return;
    }

    setSelectedIds([...selectedIds, data.id]);
    setSelectedProducts([...selectedProducts, data]);
  };

  const onChangePage = (value) => {
    setPage(value);
    setSearchParams({ page: value });
  };

  const onChangeSearchField = (value) => {
    setSearchStr(value);
  };

  const search = () => {
    setPage(1);
    setFilter([{ field: "title", value: searchStr }]);
  };

  return (
    <Container maxWidth="false">
      <Breadcrumbs aria-label="breadcrumb">
        <Link to={"/products"}>{t("menu.products")}</Link>
        <Typography color="text.primary">{t("product.barcode")}</Typography>
      </Breadcrumbs>
      <Card variant="outlined">
        <CardHeader
          title={t("menu.products")}
          action={<GenerateBarcodeDialog data={selectedProducts} />}
        />

        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              pb: 1,
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder={t("layout.search_by_title")}
              onChange={(event) => {
                onChangeSearchField(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key == "Enter") {
                  search();
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    edge="end"
                    onClick={() => {
                      search();
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
          <TableContainer elevation={0} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={"10%"}>{t("layout.id")}</TableCell>
                  <TableCell width={"30%"}>{t("layout.name")}</TableCell>
                  <TableCell width={"20%"}>{t("category.category")}</TableCell>
                  <TableCell width={"20%"}>{t("layout.status")}</TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableSkeleton row={pageSize} colSpan={5} />
              ) : (
                <TableBody>
                  {!products.length && (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        {t("layout.no_data")}
                      </TableCell>
                    </TableRow>
                  )}
                  {products.map((row) => (
                    <TableRow
                      hover
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      onClick={() => select(row)}
                    >
                      <TableCell component="th" scope="row">
                        <Checkbox
                          size="small"
                          checked={selectedIds.includes(row.id)}
                          onClick={() => select(row)}
                        />
                      </TableCell>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>
                        {row.categories.map((category) => `${category.name} `)}
                      </TableCell>
                      <TableCell>
                        <Chip
                          size="small"
                          variant="outlined"
                          color={row.isAvailable ? "success" : "error"}
                          label={
                            row.isAvailable
                              ? t("product.available")
                              : t("product.unavailable")
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </CardContent>

        <CardActions sx={{ justifyContent: "space-between", px: 2 }}>
          <Typography variant="body2">
            {t("layout.total")}: {total}
          </Typography>
          <Stack spacing={2}>
            <Pagination
              page={page}
              count={Math.ceil(total / pageSize)}
              shape="rounded"
              onChange={(event, value) => {
                onChangePage(value);
              }}
            />
          </Stack>
        </CardActions>
      </Card>
    </Container>
  );
}
