import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid2 as Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// helper
import ReactSwal from "../../helper/AlertHelper";
import { arrayCompare } from "../../helper/UnitsHelper";

// icons
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

// components
import DebugLogger from "../DebugLogger";

// api
import { ProductApi } from "../../api/ProductApi";

export default function Variants({ data, setData, isEditing }) {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [optionIds, setOptionIds] = useState([]);
  const [optionList, setOptionList] = useState([]); // full list
  const [variants, setVariants] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [lockEditing, setLockEditing] = useState(false);

  useEffect(() => {
    fetchOptionList();
    setVariants(data.variants);
    setOptionIds(data.options);
    if (isEditing) {
      setLockEditing(true);
    }
  }, []);

  useEffect(() => {
    generateVariantsData();
  }, [JSON.stringify(rowData)]);

  const fetchOptionList = async () => {
    let response = await ProductApi.productOptionFullList();
    // the option id 1 is the default option
    response = response.filter(option => option.id != 1);
    setOptionList(response);

    const selectedOptions = response
      .filter(option => {
        return data.options.includes(parseInt(option.id));
      })
      .sort((a, b) => a.id - b.id); // make sure the order is the same as the product.options
    setOptions(selectedOptions);
    setRowData(selectedOptions);
  };

  const newOption = () => {
    console.log("options", optionIds);
    const newOptionList = optionList.filter(option => !optionIds.includes(option.id));
    if (!newOptionList[0]) return;
    setOptions(options => [...options, newOptionList[0]]);
    setRowData(rowData => [...rowData, newOptionList[0]]);
    // console.log("options", options);
  };

  const onChangeOptionName = (value, index) => {
    // clone array to force trigger update the raw data
    const newRawData = JSON.parse(JSON.stringify(rowData));

    options[index] = value;
    newRawData[index] = value;

    setOptions(options);
    setRowData(newRawData);
  };

  const removeOption = index => {
    const newOptions = options.filter((option, optionIndex) => {
      return optionIndex != index;
    });

    const newRawData = rowData.filter((row, rowIndex) => {
      return rowIndex != index;
    });
    setOptions(newOptions);
    setRowData(newRawData);

    if (newRawData.length == 0) {
      setVariants([]);
      setOptionIds([]);
      setData({ ...data, options: [] });
    }
  };

  const generateVariantsData = () => {
    let attributes = {};
    let optionIds = [];

    rowData.forEach(row => {
      if (row.values.length > 0) {
        attributes[row.id] = row.values;
      }
      optionIds.push(row.id);
    });

    // make sure the order is the same as the product.options
    optionIds = optionIds.sort((a, b) => a - b);

    if (
      attributes && // null and undefined check
      Object.keys(attributes).length === 0 &&
      Object.getPrototypeOf(attributes) === Object.prototype
    ) {
      return;
    }

    let attrs = [];

    for (const [attr, values] of Object.entries(attributes)) {
      attrs.push(
        values.map(v => ({
          [attr]: {
            id: v.id,
            name: v.name,
            addonPrice: v.addonPrice,
          },
        })),
      );
    }

    attrs = attrs.reduce((a, b) => a.flatMap(d => b.map(e => ({ ...d, ...e }))));
    attrs = attrs.map(attr => {
      let optionValues = [];
      for (const [attrKey, attrValue] of Object.entries(attr)) {
        if (attrValue && attrValue.id) {
          optionValues.push(attrValue.id);
        }
      }
      return {
        optionValues: optionValues,
        optionMapping: attr,
        sku: null,
        barcode: null,
        stock: 0,
        cost: null,
        price: null,
        isAvailable: true,
      };
    });

    // console.log("variants", attrs);

    if (variants) {
      variants.forEach(variant => {
        attrs.forEach(attr => {
          if (arrayCompare(variant.optionValues, attr.optionValues)) {
            attr.id = variant.id;
            attr.sku = variant.sku;
            attr.barcode = variant.barcode;
            attr.cost = variant.cost;
            attr.price = variant.price;
            attr.stock = variant.stock;
            attr.isAvailable = variant.isAvailable;
            attr.imageIndex = variant.imageIndex || 0;
          }
        });
      });
    }
    // update table view
    setVariants(attrs);

    setData({ ...data, variants: attrs, options: optionIds });

    setOptionIds(optionIds);
  };

  const switchLockEditing = () => {
    if (lockEditing) {
      ReactSwal.fire({
        // title: t("error.title"),
        title: "warning",
        html: "Update the product variant will reset current data",
        showCancelButton: true,
        allowOutsideClick: false,
        showConfirmButton: true,
        // cancelButtonText: t("layout.cancel"),
      }).then(result => {
        if (result.isConfirmed) {
          setLockEditing(false);
        }
      });
    }

    setLockEditing(true);
  };

  const onChangeImageIndex = (event, index) => {
    let newVariants = [...variants];
    newVariants[index].imageIndex = event.target.value;
    setVariants(newVariants);
  };

  return (
    <Card variant="outlined">
      <CardHeader title={t("layout.variants")} />

      <CardContent>
        <DebugLogger title={"Product Variants"} data={data.variants} hidden={true} />
        <Box display={"flex"} alignItems={"center"} sx={{
          py: 2
        }}>
          <Typography>{t("product.product_options")}</Typography>
          {isEditing && (
            <IconButton
              onClick={() => {
                switchLockEditing();
              }}
            >
              {lockEditing ? <LockIcon /> : <LockOpenIcon />}
            </IconButton>
          )}
        </Box>

        {options.map((option, index) => (
          <Grid container spacing={2} key={index} sx={{
            alignItems: "center",
            pb: 2
          }}>
            <Grid size={{
              xs: 12,
              md: 6,
            }}>
              <Autocomplete
                disabled={lockEditing}
                disableClearable={true}
                options={optionList}
                value={options[index]}
                filterOptions={opts =>
                  opts.filter(opt => {
                    if (!optionIds.includes(opt.id) && opt.id != 1) {
                      return opt;
                    }
                  })
                }
                isOptionEqualToValue={(opt, value) => {
                  return opt.id === value.id;
                }}
                getOptionLabel={opt => opt.name}
                // defaultValue={[]}
                filterSelectedOptions
                renderOption={(props, opt) => {
                  return (
                    <Box component="li" {...props} key={opt.id}>
                      {opt.name}
                    </Box>
                  );
                }}
                onChange={(event, newValue) => {
                  onChangeOptionName(newValue, index);
                }}
                renderInput={params => <TextField required {...params} label="" />}
              />
            </Grid>

            <Grid size={{
              xs: 12,
              md: 6,
            }}>
              <IconButton disabled={lockEditing} onClick={() => removeOption(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        <Button
          disabled={lockEditing}
          variant="text"
          color="inherit"
          startIcon={<AddIcon />}
          onClick={() => newOption()}
          sx={{
            py: 2
          }}
        >
          {t("product.add_option")}
        </Button>

        <TableContainer elevation={0} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t("product.image")}</TableCell>
                {options.map((option, index) => (
                  <TableCell key={index}>{option.name}</TableCell>
                ))}
                <TableCell>{t("product.sku")}</TableCell>
                <TableCell>{t("product.barcode")}</TableCell>
                <TableCell>{t("product.cost")}</TableCell>
                <TableCell>{t("product.price")}</TableCell>
                {/* <TableCell>{t("product.addon_price")}</TableCell> */}
                <TableCell>{t("product.available")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {variants.map((variant, index) => (
                <TableRow
                  hover
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Select
                        variant="standard"
                        disableUnderline
                        value={variant.imageIndex || 0}
                        onChange={event => {
                          onChangeImageIndex(event, index);
                        }}
                        inputProps={{
                          sx: {
                            display: "flex",
                            alignItems: "center",
                            width: 40,
                          },
                        }}
                      >
                        <MenuItem value={0}>
                          <Avatar
                            variant="rounded"
                            alt={`product_variant_image_${index}`}
                            src={"/assets/images/product_placeholder.jpg"}
                            sx={{ mr: 1 }}
                          />
                        </MenuItem>
                        {data.images.map((image, index) => (
                          <MenuItem key={index} value={index + 1}>
                            <Avatar
                              variant="rounded"
                              alt={`product_variant_image_${index}`}
                              src={
                                data.images[index] || "/assets/images/product_placeholder.jpg"
                              }
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                  </TableCell>

                  {options.map((option, index) => (
                     <TableCell key={index} component="th" scope="row">
                     {variant.optionMapping[option.id]
                       ? variant.optionMapping[option.id].name
                       : ""}
                   </TableCell>
                  ))}
                  <TableCell>
                    <TextField
                      variant="outlined"
                      value={variant.sku || ""}
                      onChange={event => {
                        let newVariants = [...variants];
                        newVariants[index].sku = event.target.value;
                        setVariants(newVariants);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      value={variant.barcode || ""}
                      onChange={event => {
                        let newVariants = [...variants];
                        newVariants[index].barcode = event.target.value;
                        setVariants(newVariants);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      type={"number"}
                      inputProps={{ min: 0 }}
                      value={variant.cost || 0}
                      onChange={event => {
                        let newVariants = [...variants];
                        newVariants[index].cost = event.target.value;
                        setVariants(newVariants);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      type={"number"}
                      inputProps={{ min: 0 }}
                      value={variant.price || 0}
                      onChange={event => {
                        let newVariants = [...variants];
                        newVariants[index].price = event.target.value;
                        setVariants(newVariants);
                      }}
                    />
                  </TableCell>
                  {/* <TableCell>+{FormatHelper.formatAmount(getAddOnPrice(variant))}</TableCell> */}
                  <TableCell>
                    <Switch
                      checked={variant.isAvailable}
                      onChange={event => {
                        let newVariants = [...variants];
                        newVariants[index].isAvailable = event.target.checked;
                        setVariants(newVariants);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card >
  );
}
