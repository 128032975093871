import { Box, Card, CardContent, CardHeader, Grid2 as Grid, Skeleton } from "@mui/material";

export default function PaymentSkeleton() {

  return (
    <Box>
      <Card variant="outlined">
        <CardHeader
          subheader={<Skeleton height={50} width={"30%"} animation="wave" />}
        ></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            {Array.from(new Array(8)).map((item, index) => (
              <Grid key={index} size={{
                sx: 3,
              }}>
                <Skeleton height={100} animation="wave" />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
