export const getLandingPageName = () => {
  return "landing";
};
export const getDashboardPageName = () => {
  return "dashboard";
};
export const getWellcomePageName = () => {
  return "welcome";
};
export const getBannerPageName = () => {
  return "banner";
};
export const getMemberPageName = () => {
  return "member";
};
export const getPromotionPageName = () => {
  return "promotion";
};
export const getSupplierPageName = () => {
  return "supplier";
};
export const getRolePageName = () => {
  return "role";
};
export const getPermissionModulePageName = () => {
  return "permissionModule";
};
export const getCategoryPageName = () => {
  return "category";
};
export const getInventoryPageName = () => {
  return "inventory";
};
export const getStorePageName = () => {
  return "store";
};
export const getSettingsPageName = () => {
  return "settings";
};
export const getUserPageName = () => {
  return "user";
};
export const getProductOptionValuePageName = () => {
  return "productOptionValue";
};
export const getProductOptionPageName = () => {
  return "productOption";
};
export const getPaymentMethodPageName = () => {
  return "paymentMethod";
};
export const getProductPageName = () => {
  return "product";
};
export const getNormalProductType = () => {
  return "normal";
};
export const getServiceProductType = () => {
  return "service";
};
export const getOrderPageName = () => {
  return "order";
};
export const getSalesPageName = () => {
  return "sales";
};
export const getReceiverEmailPageName = () => {
  return "receiverEmail";
};
export const maxUploadFileMb = 100;
export const guestIdentityOption = [
  "visitor", "observer", "substitute", "member"
];
export const guestStatusOption = [
 "pending",
 "attended",
 "cancelled"
];
export const areaCodeOptions = [
  {
    value: "+1",
    name: "+1"
  },
  {
    value: "+60",
    name: "+60"
  },
  {
    value: "+61",
    name: "+61"
  },
  {
    value: "+63",
    name: "+63"
  },
  {
    value: "+65",
    name: "+65"
  },
  {
    value: "+66",
    name: "+66"
  },
  {
    value: "+81",
    name: "+81"
  },
  {
    value: "+86",
    name: "+86"
  },
  {
    value: "+852",
    name: "+852"
  },
  {
    value: "+853",
    name: "+853"
  },
  {
    value: "+886",
    name: "+886"
  },
];

export const genderList = [
  {
    key: "M",
    value: "M",
  },
  {
    key: "F",
    value: "F",
  },
];

// promotion type
export const getBuyXPayYPromotionType = () => {
  return "buy_x_pay_y";
};

export const getBuyItemXGetDiscountYPromotionType = () => {
  return "buy_item_x_get_discount_y";
};

export const getBuyAmountGetDiscountYPromotionType = () => {
  return "buy_amount_get_discount_y";
};

export const getExtraItemPurchaseDiscountPromotionType = () => {
  return "extra_item_purchase_discount";
};

export const getOrderRedemptionOfferPromotionType = () => {
  return "purchase_xy_get_z_discount";
};

export const storeTypeList = [
  {
    key: 1,
    nameZh: "現金商店",
    nameEn: "Retail",
    value: "retail",
  },
  {
    key: 2,
    nameZh: "點數商店",
    nameEn: "Eshop",
    value: "eshop",
  },
];

export const paymentMethodDefaultType = "system";
export const paymentMethodCustomType = "custom";