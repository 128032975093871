import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormatHelper } from "../../helper/FormatHelper";
import DebugLogger from "../DebugLogger";
import ImagePlaceholder from "../ImagePlaceholder";
import ValidationTextField from "../ValidationTextField";

export default function Summary({ data, setData }) {
  const { t } = useTranslation();

  const getShipStatus = status => {
    switch (status) {
      case "delivered":
        return t("order.delivered");
      case "dispatch":
        return t("order.dispatch");
      case "unship":
        return t("order.unship");
      default:
        return t("order.unship");
    }
  };

  const disableShipQty = () => {
    let disabled = false;

    let orderStatus = data.status;

    if (orderStatus === "refund" || orderStatus === "cancel") {
      disabled = true;
    }

    return disabled;
  };

  return (
    <Card variant="outlined">
      <CardHeader title={t("order.summary")} />

      <CardContent>
        <DebugLogger title="Order Summary" data={data} hidden={true}></DebugLogger>
        <TableContainer elevation={0} component={Paper}>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableBody>
              {data.items.map(row => (
                <TableRow hover key={row.id}>
                  <TableCell width={"10%"}>
                    <ImagePlaceholder
                      styleProps={{ height: 80, maxwidth: 150 }}
                      url={row?.product?.images ? row.product.images[0] : null}
                    />
                  </TableCell>
                  <TableCell width={"10%"}>{row.productTitle}</TableCell>
                  <TableCell width={"10%"}>x {row.qty}</TableCell>
                  <TableCell width={"10%"}>
                    <Typography variant="body">
                      {FormatHelper.formatAmount(row.finalPrice)}
                    </Typography>
                    {row.promotion && (
                      <Box>
                        <Typography variant="cation" color={"error"}>
                          {row.promotion.name}
                        </Typography>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell width={"10%"}>
                    <Chip
                      variant="outlined"
                      color={row.shipStatus == "delivered" ? "success" : "error"}
                      label={getShipStatus(row.shipStatus)}
                    />
                  </TableCell>
                  <TableCell width={"15%"}>
                    <ValidationTextField
                      name={"shipQty"}
                      type={"number"}
                      value={row.shipQty || 0}
                      sx={{
                        minWidth: 40,
                      }}
                      onChange={e => {
                        setData({
                          ...data,
                          items: data.items.map(item => {
                            if (item.id === row.id) {
                              item.shipQty = e.target.value;
                            }
                            return item;
                          }),
                        });
                      }}
                      disabled={disableShipQty(row)}
                      title={t("order.ship_qty")}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box display={"flex"} py={1} justifyContent={"space-between"}>
          <Typography variant="body">{t("layout.discount_price")}</Typography>
          <Typography variant="body">
            {FormatHelper.formatAmount(data.originTotal - data.finalTotal)}
          </Typography>
        </Box>

        {data.promotions &&
          data.promotions.map((promotion, index) => (
            <Box key={index} display={"flex"} py={1} justifyContent={"space-between"}>
              <Typography variant="cation" color={"error"}>
                {promotion.name}
              </Typography>
            </Box>
          ))}

        <Box display={"flex"} py={1} justifyContent={"space-between"}>
          <Typography variant="body">{t("layout.final_price")}</Typography>
          <Typography variant="body">{FormatHelper.formatAmount(data.finalTotal)}</Typography>
        </Box>

        <Box display={"flex"} py={1} justifyContent={"space-between"}>
          <Typography variant="body">{t("layout.paid_price")}</Typography>
          <Typography variant="body">{FormatHelper.formatAmount(data.paidTotal)}</Typography>
        </Box>

        <Box display={"flex"} py={1} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant="h6">{t("layout.change")}</Typography>
          <Typography variant="h6">{FormatHelper.formatAmount(data.change)}</Typography>
        </Box>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
