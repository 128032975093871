import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

// icons
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

export default function PaymentInputDialog({ payment, open, onClose, onSubmit, unpaid }) {
  const { t } = useTranslation();

  useEffect(() => {
    if (unpaid > 0) {
      payment.amount = parseFloat(unpaid);
    }
  }, []);

  const handleClose = () => {
    onClose(null);
  };

  const onChangeField = value => {
    payment.amount = parseFloat(value);
  };

  const handleKeyDown = event => {
    if (event.key == "Enter") {
      onSubmit(payment);
      event.preventDefault();
    }
  };

  return (
    <Box>
      {open && (
        <Dialog onClose={handleClose} open={open} scroll={"body"}>
          <Card variant="outlined" sx={{ width: 400 }}>
            <CardHeader title={payment.name} />

            <Divider />

            <CardContent sx={{ height: 100 }}>
              <Box>
                <TextField
                  // autoFocus
                  type={"number"}
                  fullWidth
                  variant="outlined"
                  defaultValue={unpaid > 0 ? unpaid : 0}
                  onChange={event => {
                    onChangeField(event.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                  onFocus={event => {
                    event.target.select();
                  }}
                  InputProps={{
                    startAdornment: <AttachMoneyIcon />,
                    inputProps: {
                      min: 0,
                      max: 9999999,
                    },
                  }}
                />
              </Box>
            </CardContent>

            <Divider />

            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  handleClose();
                }}
              >
                {t("layout.cancel")}
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={() => {
                  onSubmit(payment);
                }}
              >
                {t("layout.ok")}
              </Button>
            </CardActions>
          </Card>
        </Dialog>
      )}
    </Box>
  );
}
