import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Breadcrumbs,
  Card,
  CardHeader,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PromotionApi } from "../../api/PromotionApi";
import ActionFooter from "../../components/ActionFooter";
import Skeleton from "../../components/category/Skeleton";
import Bundle from "../../components/promotion/Bundle";
import Configuration from "../../components/promotion/Configuration";
import General from "../../components/promotion/General";
import ItemDiscount from "../../components/promotion/ItemDiscount";
import OrderDiscount from "../../components/promotion/OrderDiscount";
import Type from "../../components/promotion/Type";
import ReactSwal from "../../helper/AlertHelper";
import DebugLogger from "../../components/DebugLogger";
import {
  getBuyAmountGetDiscountYPromotionType,
  getBuyItemXGetDiscountYPromotionType,
  getBuyXPayYPromotionType,
} from "../../helper/GlobalValueHelper";

// permission
import { useSelector } from "react-redux";
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

export default function Edit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState({
    name: "",
    startAt: null,
    endAt: null,
    logicType: getBuyXPayYPromotionType(),
    reqMemberLevel: 0,
    parameter: null,
    bundle: {
      selectedVariant: [],
      qty: 0,
      amount: 0,
    },
    itemDiscount: {
      selectedVariant: [],
      type: "percent",
      value: 0,
    },
  });
  const [isLoading, setIsLoading] = useState(true);
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [haveDeletePermission, setHaveDeletePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const deletePermission = checkPermission(currentUser, currentPage, "delete");

    setHaveDeletePermission(deletePermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const result = await PromotionApi.details(id);
      let parameter = [];
      parameter = result.parameter;

      if (result.logicType === getBuyXPayYPromotionType()) {
        setData({
          ...result,
          bundle: {
            selectedVariant: parameter.variants,
            qty: parameter.qty,
            amount: parameter.amount,
          },
          // for init
          itemDiscount: {
            selectedVariant: [],
            type: "percent",
            value: 0,
          },
          orderDiscount: {
            amount: 0,
            type: "percent",
            value: 0,
          },
        });
      } else if (result.logicType === getBuyItemXGetDiscountYPromotionType()) {
        setData({
          ...result,
          itemDiscount: {
            selectedVariant: parameter.variants,
            type: parameter.type,
            value: parameter.value,
          },
          bundle: {
            selectedVariant: [],
            qty: 0,
            amount: 0,
          },
          orderDiscount: {
            amount: 0,
            type: "percent",
            value: 0,
          },
        });
      } else if (result.logicType === getBuyAmountGetDiscountYPromotionType()) {
        setData({
          ...result,
          itemDiscount: {
            selectedVariant: [],
            type: "percent",
            value: 0,
          },
          bundle: {
            selectedVariant: [],
            qty: 0,
            amount: 0,
          },
          orderDiscount: {
            amount: parameter.amount,
            type: parameter.type,
            value: parameter.value,
          },
        });
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }finally{
      setIsLoading(false);
    }
  };

  const onSubmit = async () => {
    try {
      if (!data.name) {
        toast.error("Please input promotion name");
        return;
      }

      setFullScreenLoading(true);
      const success = await PromotionApi.update(id, data);
      setFullScreenLoading(false);
      if (success) {
        toast.success(t("layout.updated_successfully"));
      }
    } catch (error) {
      console.error(error);
      setFullScreenLoading(false);
    }
  };

  const onCancel = async () => {
    navigate(-1);
  };

  const onDelete = async () => {
    try {
      setFullScreenLoading(true);
      const success = await PromotionApi.deletePromotion(id);
      if (success) {
        toast.success(t("layout.deleted_successfully"));
        navigate("/promotions");
      }
      setFullScreenLoading(false);
    } catch (error) {
      setFullScreenLoading(false);
      console.log(error);
    }
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        onDelete();
      }
    });
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Container maxWidth="false">
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/promotions"}>{t("menu.promotions")}</Link>
        <Typography color="text.primary">{t("layout.edit")}</Typography>
      </Breadcrumbs>
      <Card variant="outlined">
        <CardHeader
          action={
            id ? (
              <Box>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem disabled={!haveDeletePermission} onClick={showDeleteConfirm}>
                    <Typography color={"error"}>{t("layout.delete")}</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Box />
            )
          }
        />
        {/* Discount type */}
        <Type data={data} setData={setData} />

        <Divider />

        {/* General */}
        <General data={data} setData={setData} />

        <Divider />

        {/* Configuration */}
        <Configuration data={data} setData={setData} />

        <Divider />

        {/* Conditions */}
        {data.logicType === getBuyXPayYPromotionType() && (
          <Bundle data={data} setData={setData} />
        )}

        {data.logicType === getBuyItemXGetDiscountYPromotionType() && (
          <ItemDiscount data={data} setData={setData} />
        )}

        {data.logicType === getBuyAmountGetDiscountYPromotionType() && (
          <OrderDiscount data={data} setData={setData} />
        )}
      </Card>
      <Box py={8} />
      <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
    </Container>
  );
}
