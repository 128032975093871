import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import { toast } from "react-toastify";

// helper
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// icons
import AddIcon from "@mui/icons-material/Add";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import EditIcon from "@mui/icons-material/Edit";

// components
import ActionFooter from "../../components/ActionFooter";
import ImagePlaceholder from "../../components/ImagePlaceholder";
import Skeleton from "../../components/payment/Skeleton";

// api
import { PaymentApi } from "../../api/PaymentApi";
import DebugLogger from "../../components/DebugLogger";

export default function List() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [haveCreatePermission, setHaveCreatePermission] = useState(false);
  const [haveEditPermission, setHaveEditPermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const createPermission = checkPermission(currentUser, currentPage, "create");

    const editPermission = checkPermission(currentUser, currentPage, "update");

    setHaveCreatePermission(createPermission);
    setHaveEditPermission(editPermission);
  }, [currentUser, currentPage]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await PaymentApi.list(1, -1, []);
      const sorted = response.data.sort((a, b) => a.priority - b.priority);
      setData(sorted);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const create = () => {
    navigate(`/payment-method/create`);
  };

  const onSubmit = async () => {
    console.log(data);
    const susccess = await PaymentApi.updatePriorityAvailability(data);
    if (susccess) {
      toast.success(t("layout.updated_successfully"));
      fetchData();
    }
  };

  const onCancel = () => {
    navigate("/settings");
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Container maxWidth={false}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/settings"}>{t("menu.settings")}</Link>
        <Typography color="text.primary">{t("layout.payment_method")}</Typography>
      </Breadcrumbs>
      <Card variant="outlined">
        <CardHeader
          title={t("layout.payment_method")}
          action={
            haveCreatePermission && (
              <IconButton onClick={create}>
                <AddIcon />
              </IconButton>
            )
          }
        />

        <CardContent>
          <DebugLogger title="Payment Method" data={data} hidden={true}/>
          <Box sx={{ p: 2 }}>
            <ReactSortable
              list={data}
              setList={setData}
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                gap: 8,
                alignContent: "flex-start",
              }}
            >
              {data.map((paymentMethod, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  {paymentMethod.icon ? (
                    <ImagePlaceholder
                      url={paymentMethod.icon}
                      styleProps={{
                        width: "100px",
                        height: "100px",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <Box sx={{ width: "100px", height: "100px" }} />
                  )}

                  <Box display={"flex"} alignItems={"center"}>
                    <DragIndicatorIcon sx={{ fontSize: 14 }} />
                    <Typography variant="body2" align="center" px={1}>
                      {paymentMethod.name}
                    </Typography>
                    {paymentMethod.type != "system" && haveEditPermission && (
                      <IconButton
                        onClick={() => {
                          navigate(`/payment-method/${paymentMethod.id}`);
                        }}
                      >
                        <EditIcon sx={{ fontSize: 14 }} />
                      </IconButton>
                    )}
                  </Box>
                  <Switch
                    checked={paymentMethod.isAvailable}
                    onChange={() => {
                      const newPaymentMethods = [...data];
                      newPaymentMethods[index].isAvailable = !newPaymentMethods[index].isAvailable;
                      setData(newPaymentMethods);
                    }}
                  />
                </Box>
              ))}
            </ReactSortable>
          </Box>
        </CardContent>
      </Card>
      <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
    </Container>
  );
}
