import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid2 as Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function FilterButton({ searchStr, setFilter, storeList }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState("all");
  const [shipStatus, setShipStatus] = useState("all");
  const [orderStatus, setOrderStatus] = useState("all");
  const [memberName, setMemberName] = useState("");
  const [memberPhone, setMemberPhone] = useState("");
  const [memberEmail, setMemberEmail] = useState("");
  const [store, setStore] = useState("all");

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeStartDate = value => {
    setStartDate(value);
    console.log("value", value);
  };

  const onChangeEndDate = value => {
    setEndDate(value);
    console.log("value", value);
  };

  const onChangePayStatus = event => {
    console.log("value", event.target.value);
    setPaymentStatus(event.target.value);
  };

  const onChangeShipStatus = event => {
    console.log("value", event.target.value);
    setShipStatus(event.target.value);
  };

  const onChangeOrderStatus = event => {
    console.log("value", event.target.value);
    setOrderStatus(event.target.value);
  };

  const onChangeStore = event => {
    console.log("value", event.target.value);
    setStore(event.target.value);
  };

  const onChangeFilter = () => {
    let filters = [];

    if (searchStr) {
      filters.push({ field: "order_num", value: searchStr });
    }

    if (startDate) {
      filters.push({ field: "created_at", value: startDate, op: "gte" });
    }

    if (endDate) {
      filters.push({ field: "created_at", value: endDate, op: "lte" });
    }

    if (memberName) {
      filters.push({ field: "customer_name", value: memberName });
    }

    if (store != "all") {
      filters.push({ field: "store_id", value: store });
    } else {
      filters.push({ field: "store_id", value: "" });
    }

    if (memberPhone) {
      filters.push({ field: "member_phone", value: memberPhone });
    }

    if (memberEmail) {
      filters.push({ field: "member_email", value: memberEmail });
    }

    if (paymentStatus != "all") {
      filters.push({ field: "pay_status", value: paymentStatus });
    }

    if (shipStatus != "all") {
      filters.push({ field: "ship_status", value: shipStatus });
    }

    if (orderStatus != "all") {
      filters.push({ field: "status", value: orderStatus });
    }

    if (!filters.length) {
      filters.push({ field: "order_num", value: "" });
    }

    setFilter(filters);
    handleClose();
  };

  const clearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setPaymentStatus("all");
    setShipStatus("all");
    setOrderStatus("all");
    setStore("all");
    setMemberName("");
    setMemberPhone("");
    setMemberEmail("");
    setMemberNfc("");
    setFilter([{ field: "order_num", value: "" }]);
    handleClose();
  };

  return (
    <Box>
      <Button
        sx={{ p: 1, mx: 2 }}
        variant="outlined"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {t("layout.filter")}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box sx={{ p: 2, width: 600 }}>
          <Typography variant="h6" sx={{
            pb: 2,
          }}>{t("layout.filter")}</Typography>
          <Divider />

          <Grid container spacing={2} sx={{
            py: 2,
          }}>
            <Grid size={{
              xs: 6,
            }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  ampm={false}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  slotProps={{ textField: { fullWidth: true } }}
                  label={t("layout.start_date")}
                  value={startDate}
                  onChange={newValue => {
                    onChangeStartDate(newValue);
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid size={{
              xs: 6,
            }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  ampm={false}
                  inputFormat="YYYY-MM-DD HH:mm:ss"
                  slotProps={{ textField: { fullWidth: true } }}
                  label={t("layout.end_date")}
                  value={endDate}
                  onChange={newValue => {
                    onChangeEndDate(newValue);
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid size={{
              xs: 6,
            }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("order.payment_status")}</InputLabel>

                <Select
                  label={t("order.payment_status")}
                  value={paymentStatus}
                  fullWidth
                  onChange={onChangePayStatus}
                >
                  <MenuItem value={"all"}>{t("layout.all")}</MenuItem>
                  <MenuItem value={"full_paid"}>{t("order.paid")}</MenuItem>
                  <MenuItem value={"partial_paid"}>{t("order.partial_paid")}</MenuItem>
                  <MenuItem value={"pending"}>{t("order.unpaid")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid size={{
              xs: 6,
            }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("order.ship_status")}</InputLabel>

                <Select
                  label={t("order.ship_status")}
                  value={shipStatus}
                  fullWidth
                  onChange={onChangeShipStatus}
                >
                  <MenuItem value={"all"}>{t("layout.all")}</MenuItem>
                  <MenuItem value={"delivered"}>{t("order.delivered")}</MenuItem>
                  <MenuItem value={"dispatch"}>{t("order.dispatch")}</MenuItem>
                  <MenuItem value={"pending"}>{t("order.unship")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid size={{
              xs: 6,
            }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("order.order_status")}</InputLabel>
                <Select
                  label={t("order.order_status")}
                  value={orderStatus}
                  fullWidth
                  onChange={onChangeOrderStatus}
                >
                  <MenuItem value={"all"}>{t("layout.all")}</MenuItem>
                  <MenuItem value={"complete"}>{t("order.complete")}</MenuItem>
                  <MenuItem value={"pending"}>{t("order.pending")}</MenuItem>
                  <MenuItem value={"refund"}>{t("order.refund")}</MenuItem>
                  <MenuItem value={"cancel"}>{t("order.cancel")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid size={{
              xs: 6,
            }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t("menu.stores")}</InputLabel>

                <Select
                  label={t("menu.stores")}
                  value={store}
                  fullWidth
                  onChange={onChangeStore}
                >
                  <MenuItem value={"all"}>{t("layout.all")}</MenuItem>
                  {storeList.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Divider />

          <Stack spacing={2} sx={{
            py: 2,
          }}>
            <TextField
              fullWidth
              value={memberName}
              onChange={e => setMemberName(e.target.value)}
              label={t("order.member_name")}
            />

            <TextField
              fullWidth
              value={memberPhone}
              onChange={e => setMemberPhone(e.target.value)}
              label={t("order.member_phone")}
            />

            <TextField
              fullWidth
              value={memberEmail}
              onChange={e => setMemberEmail(e.target.value)}
              label={t("order.member_email")}
            />
          </Stack>

          <Divider sx={{ mb: 2 }} />
          <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
            <Button
              variant="outlined"
              onClick={() => {
                clearFilter();
              }}
            >
              {t("layout.clear_filter")}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                onChangeFilter();
              }}
            >
              {t("layout.ok")}
            </Button>
          </Box>
        </Box>
      </Menu>
    </Box>
  );
}
