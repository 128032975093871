import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

// helper
import { FormatHelper } from "../../helper/FormatHelper";

// icons
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// components
import UpdatePaymentDialog from "./UpdatePaymentDialog";

// api
import { OrderApi } from "../../api/OrderApi";

export default function Payment({ data, fetchData, haveEditPermission }) {
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [showEditPaymentDialog, setShowEditPaymentDialog] = useState(false);
  const [formattedPayments, setFormattedPayments] = useState([]);

  useEffect(() => {
    const payments = data.payments.map(payment => {
      if (payment.code == "change") {
        return {
          ...payment,
          name: t("layout.change"),
          amount: parseFloat(payment.amount) * -1,
        };
      } else {
        return {
          ...payment,
        };
      }
    });

    setFormattedPayments(payments);
  }, [data]);

  useEffect(() => {
    let t = 0;
    formattedPayments.forEach(payment => {
      t += payment.amount;
    });

    setTotal(t);
  }, [formattedPayments]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeEditPaymentDialog = () => {
    setShowEditPaymentDialog(false);
    fetchData();
  };

  const handleUpdatePaymentMethod = async payments => {
    console.log("handleUpdatePaymentMethod", payments);

    if (payments.length == 0) {
      toast.error(t("error.please_enter_payment_method"));
      return;
    }

    const success = await OrderApi.updatePayment(data.id, payments);

    if (success) {
      closeEditPaymentDialog();
      toast.success(t("layout.updated_successfully"));
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={t("order.payment")}
        action={
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Box display={"flex"} alignItems={"center"} height={50}>
              <Box display={"flex"} alignItems={"center"} px={1}>
                <FiberManualRecordIcon
                  fontSize="8"
                  color={data.payStatus == "full_paid" ? "success" : "error"}
                />
                <Typography variant="caption">
                  {data.payStatus == "full_paid" ? t("order.paid") : t("order.unpaid")}
                </Typography>
              </Box>
            </Box>
            {data.status != "refund" && data.status != "cancel" && (
              <Box>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    disabled={!haveEditPermission}
                    onClick={() => {
                      setShowEditPaymentDialog(true);
                      handleClose();
                    }}
                  >
                    <Typography>{t("layout.edit")}</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
        }
      />

      <CardContent>
        <TableContainer elevation={0} component={Paper}>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
          >
            <TableBody>
              {formattedPayments.map((row, index) => (
                <TableRow hover key={index}>
                  <TableCell padding={"none"} sx={{ textAlign: "start" }}>
                    {row.name}
                  </TableCell>
                  <TableCell padding={"none"} sx={{ textAlign: "end" }}>
                    {FormatHelper.formatAmount(row.amount)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display={"flex"} py={1} justifyContent={"space-between"} alignItems={"center"}>
          <Typography variant="h6">{t("layout.total")}</Typography>
          <Typography variant="h6">{FormatHelper.formatAmount(total)}</Typography>
        </Box>
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>

      <UpdatePaymentDialog
        data={data}
        open={showEditPaymentDialog}
        onUpdate={handleUpdatePaymentMethod}
        onClose={closeEditPaymentDialog}
      />
    </Card>
  );
}
