import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// icons
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import UploadIcon from "@mui/icons-material/Upload";

// components
import DebugLogger from "../../components/DebugLogger";
import TableSkeleton from "../../components/TableSkeleton";
import ExportButton from "../../components/member/ExportButton";

// api
import { MemberApi } from "../../api/MemberApi";

export default function List() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get("page")) || 1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState([]);
  const [searchStr, setSearchStr] = useState();
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [haveCreatePermission, setHaveCreatePermission] = useState(false);
  const [haveUpdatePermission, setHaveUpdatePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const [colSpan, setColSpan] = useState(7);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    const createPermission = checkPermission(currentUser, currentPage, "create");
    setHaveCreatePermission(createPermission);

    const updatePermission = checkPermission(currentUser, currentPage, "update");
    setHaveUpdatePermission(updatePermission);
  }, [currentUser, currentPage]);

  useEffect(() => {
    if (!filter || filter.length <= 0) return;
    setPage(1);
    fetchData();
  }, [filter]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await MemberApi.list(page, pageSize, filter);
      setIsLoading(false);
      setData(response.data);
      setTotal(response.total);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const viewDetail = id => {
    navigate(`/members/${id}`);
  };

  const newMember = () => {
    navigate(`/members/create`);
  };

  const onChangePage = value => {
    setPage(value);
    setSearchParams({ page: value });
  };

  const onChangeSearchField = value => {
    setSearchStr(value);
  };

  const search = () => {
    setPage(1);

    setFilter([
      {
        field: "first_name, last_name",
        value: searchStr,
        op: "like",
      },
    ]);
  };

  const uploadMember = async e => {
    const file = e.target.files[0];
    try {
      setIsLoading(true);
      const response = await MemberApi.uploadMembers(file);
      if (response) {
        toast.success(t("layout.upload_successfully"));
        e.target.value = null;
      }
      fetchData();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="false">
      <Card variant="outlined">
        <CardHeader
          title={<Translation>{t => <Box> {t("menu.members")} </Box>}</Translation>}
          action={
            <Box display={"flex"} gap={1}>
              {haveCreatePermission && <ExportButton filename="Member Records" />}
              {haveCreatePermission && (
                <Button
                  component="label"
                  color="inherit"
                  variant="outlined"
                  startIcon={<UploadIcon />}
                >
                  {t("layout.inport")}
                  <Box
                    component={"input"}
                    type="file"
                    onChange={uploadMember}
                    sx={{
                      display: "none",
                    }}
                    accept=".csv"
                  />
                </Button>
              )}
              {haveCreatePermission && (
                <Button
                  color="inherit"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={newMember}
                >
                  <Translation>{t => t("member.new_member")}</Translation>
                </Button>
              )}
            </Box>
          }
        />

        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              pb: 1,
            }}
          >
            <TextField
              placeholder={t("layout.search_by_name")}
              onChange={event => {
                onChangeSearchField(event.target.value);
              }}
              onKeyDown={event => {
                if (event.key == "Enter") {
                  search();
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    edge="end"
                    onClick={() => {
                      search();
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
          <TableContainer elevation={0} component={Paper}>
            <DebugLogger title="Members" data={data} hidden={true}></DebugLogger>

            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={"5%"}>{t("member.id")}</TableCell>
                  <TableCell width={"5%"}>{t("member.profile_photo")}</TableCell>
                  <TableCell width={"10%"}>{t("member.name")}</TableCell>
                  <TableCell width={"10%"}>{t("member.email")}</TableCell>
                  <TableCell width={"10%"}>{t("member.phone_number")}</TableCell>
                  <TableCell width={"10%"}>{t("layout.updated_by")}</TableCell>
                  <TableCell width={"10%"}>{t("layout.updated_at")}</TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableSkeleton row={pageSize} colSpan={colSpan} />
              ) : (
                <TableBody>
                  {!data.length && (
                    <TableRow>
                      <TableCell colSpan={colSpan} align="center">
                        {t("layout.no_data")}
                      </TableCell>
                    </TableRow>
                  )}
                  {data.map((row, index) => (
                    <TableRow
                      hover
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}
                      onClick={() =>
                        haveUpdatePermission
                          ? viewDetail(row.id)
                          : toast.error(t("error.no_update_permission"))
                      }
                    >
                      <TableCell>{row.id}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          <Avatar
                            sx={{ border: "1px solid #0000001f" }}
                            alt={`member_icon_${index}`}
                            src={row.photo}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell>{row.firstName} {row.lastName}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.phoneNumber}</TableCell>
                      <TableCell>{row.updatedBy}</TableCell>
                      <TableCell>{row.updatedAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </CardContent>

        <CardActions sx={{ justifyContent: "space-between", px: 2 }}>
          <Typography variant="body2">
            {t("layout.total")}: {total}
          </Typography>
          <Stack spacing={2}>
            <Pagination
              page={page}
              count={Math.ceil(total / pageSize)}
              shape="rounded"
              onChange={(event, value) => {
                onChangePage(value);
              }}
            />
          </Stack>
        </CardActions>
      </Card>
    </Container>
  );
}
