import * as React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Breadcrumbs,
  Card,
  CardHeader,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PromotionApi } from "../../api/PromotionApi";
import ActionFooter from "../../components/ActionFooter";
import Skeleton from "../../components/category/Skeleton";
import Bundle from "../../components/promotion/Bundle";
import Configuration from "../../components/promotion/Configuration";
import General from "../../components/promotion/General";
import ItemDiscount from "../../components/promotion/ItemDiscount";
import OrderDiscount from "../../components/promotion/OrderDiscount";
import Type from "../../components/promotion/Type";
import ReactSwal from "../../helper/AlertHelper";
import DebugLogger from "../../components/DebugLogger";
import {
  getBuyAmountGetDiscountYPromotionType,
  getBuyItemXGetDiscountYPromotionType,
  getBuyXPayYPromotionType,
} from "../../helper/GlobalValueHelper";

// permission
import { useSelector } from "react-redux";
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";
import FullScreenLoading from "../../components/FullScreenLoading";

export default function Create() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    name: "",
    startAt: null,
    endAt: null,
    logicType: getBuyXPayYPromotionType(),
    reqMemberLevel: 0,
    parameter: null,
    bundle: {
      selectedVariant: [],
      qty: 0,
      amount: 0,
    },
    itemDiscount: {
      selectedVariant: [],
      type: "percent", // or fixed
      value: 0,
    },
    orderDiscount: {
      amount: 0,
      type: "percent", // or fixed
      value: 0,
    },
  });
  const [fullScreenLoading, setFullScreenLoading] = useState(false);

  const onSubmit = async () => {
    try {
      console.log("submit", data);

      if (!data.name) {
        toast.error("Please input promotion name");
        return;
      }

      setFullScreenLoading(true);
      const id = await PromotionApi.create(data);
      setFullScreenLoading(false);
      if (id) {
        toast.success(t("layout.created_successfully"));
        navigate(`/promotions/${id}`);
      }
    } catch (error) {
      console.error(error);
      setFullScreenLoading(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="false">
      <FullScreenLoading open={fullScreenLoading} />
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to={"/promotions"}>{t("menu.promotions")}</Link>
        <Typography color="text.primary">{t("layout.create")}</Typography>
      </Breadcrumbs>
      <Box component="form">
        <Card variant="outlined">
          {/* Discount type */}
          <Type data={data} setData={setData} />

          <Divider />

          {/* General */}
          <General data={data} setData={setData} />

          <Divider />

          {/* Configuration */}
          <Configuration data={data} setData={setData} />

          <Divider />

          {/* Conditions */}
          {data.logicType === getBuyXPayYPromotionType() && (
            <Bundle data={data} setData={setData} />
          )}

          {data.logicType === getBuyItemXGetDiscountYPromotionType() && (
            <ItemDiscount data={data} setData={setData} />
          )}

          {data.logicType === getBuyAmountGetDiscountYPromotionType() && (
            <OrderDiscount data={data} setData={setData} />
          )}
        </Card>
        <Box sx={{ my: 8 }} />
        <ActionFooter onSubmit={onSubmit} onCancel={onCancel} />
      </Box>
    </Container>
  );
}
