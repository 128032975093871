import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getPromotionPageName } from "../helper/GlobalValueHelper";
import Create from "../views/promotion/Create";
import Edit from "../views/promotion/Edit";
import List from "../views/promotion/List";

const PromotionRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/promotions",
      element: (
        <AuthProvider page={getPromotionPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "/promotions/:id",
      element: (
        <AuthProvider page={getPromotionPageName()} action={"view"}>
          <Edit />
        </AuthProvider>
      ),
    },
    {
      path: "/promotions/create",
      element: (
        <AuthProvider page={getPromotionPageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
  ],
};

export default PromotionRoute;
