import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Skeleton,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import QRCode from "react-qr-code";
import styled from "@emotion/styled";
import { getVariantName } from "../../helper/UnitsHelper";

const A4Paper = styled("div")({
  // width: "210mm",
  width: "100%",
  // background: "red",
  display: "flex",
  flexDirection: "row",
  alignContent: "flex-start",
  flexWrap: "wrap",
});

export default function QrcodeList({ data, quantity, startWith }) {
  const [variants, setVariants] = useState([]);

  useEffect(() => {
    const items = [];
    data.forEach((product) => {
      product.variants.forEach((variant) => {
        items.push({
          ...variant,
          productName: product.title,
        });
      });
    });

    setVariants(items);
  }, [data]);

  if (!quantity || quantity < 0) return <Box />;

  if (quantity > 99) return <Box>Error: maximum quantity</Box>;

  if (startWith > 99) return <Box>Error: maximum startWith</Box>;

  if (variants.length === 0) return <Box />;

  return (
    <A4Paper>
      {[...Array(startWith ? startWith - 1 : 0)].map((e, i) => (
        <Box
          key={i}
          sx={{
            width: "52.45mm",
            height: "29.63mm",
            // backgroundColor: "blue",
            // border: "1px solid white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        ></Box>
      ))}
      {variants.map((variants) =>
        [...Array(quantity)].map((e, i) => (
          <Box
            key={i}
            sx={{
              width: "52.45mm",
              height: "29.63mm",
              // backgroundColor: "blue",
              // border: "1px solid white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              flexDirection: "column",
            }}
          >
            <Typography variant={"caption"} textAlign={"center"}>
              {variants.productName} {getVariantName(variants)}
            </Typography>
            <QRCode
              style={{
                margin: 8,
              }}
              value={variants.barcode || ""}
            />
          </Box>
        ))
      )}
    </A4Paper>
  );
}
