import axios from "../helper/AxiosHelper";

const list = async (page, pageSize, filters) => {
  const query = {
    filters: filters || undefined,
    page: page || 1,
    page_size: pageSize || 10,
  };
  const response = await axios.get(`/promotion/query?query=${JSON.stringify(query)}`);
  const result = response.data.result;

  return {
    data: result.data.map(data => {
      return {
        id: data.id,
        name: data.name,
        startAt: data.start_at,
        endAt: data.end_at,
        logicType: data.logic_type,
        reqMemberLevel: data.req_member_level,
        parameter: data.parameter,
        updatedAt: data.updated_at,
      };
    }),
    page: result.page,
    pageSize: result.page_size,
    total: result.total,
  };
};

const details = async id => {
  const response = await axios.get(`promotion/show/${id}`);
  const result = response.data.result;
  return {
    id: result.id,
    name: result.name,
    startAt: result.start_at,
    endAt: result.end_at,
    logicType: result.logic_type,
    reqMemberLevel: result.req_member_level,
    parameter: result.parameter,
  };
};

const create = async input => {
  const data = {
    name: input.name,
    start_at: input.startAt,
    end_at: input.endAt,
    logic_type: input.logicType,
    req_member_level: input.reqMemberLevel,
    parameter: input.parameter,
  };

  const response = await axios.post(`promotion/create`, data);

  return response.data.result.insert_id;
};

const update = async (id, input) => {
  const data = {
    name: input.name,
    start_at: input.startAt,
    end_at: input.endAt,
    logic_type: input.logicType,
    req_member_level: input.reqMemberLevel,
    parameter: input.parameter,
  };

  const response = await axios.post(`promotion/update/${id}`, data);
  return response.data.success;
};

const deletaData = async id => {
  const response = await axios.post(`promotion/delete/${id}`);
  return response.data.success;
};

export const PromotionApi = {
  list,
  details,
  create,
  update,
  deletaData,
};
