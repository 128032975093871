import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ReactSwal from "../../helper/AlertHelper";
import { maxUploadFileMb } from "../../helper/GlobalValueHelper";
import { resizeFile } from "../../helper/UnitsHelper";
import DebugLogger from "../DebugLogger";
import ImageUploader from "../ImageUploader";
import UpdaterInfo from "../UpdaterInfo";

export default function BaseInfo({
  data,
  setData,
  deleteData,
  haveDeletePermission,
  storesList
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [images, setImages] = useState([]);
  const [showImageUploader, setShowImageUploader] = useState(false);

  useEffect(() => {
    if (!data) return;
    let imageLength = data.images?.length;
    imageLength += data.uploadFiles?.length || 0;

    let variantLength = 1;

    if (imageLength >= variantLength) {
      setShowImageUploader(false);
    } else {
      setShowImageUploader(true);
    }
  }, [data]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showDeleteConfirm = () => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        deleteData();
      }
    });
  };

  const onImageChange = async images => {
    let uploadFiles = data.uploadFiles || [];

    // check if file exists
    let fileExists = false;
    uploadFiles.forEach(file => {
      images.forEach(image => {
        if (file.name === image.file.name) {
          fileExists = true;
          return;
        }
      });
    });

    if (fileExists) {
      return;
    }

    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        if (image.file.size > 1024 * 1024 * maxUploadFileMb) {
          toast.error(`Image ${index + 1} file size must be less than ${maxUploadFileMb}MB`);
          return;
        }

        const resizedImage = await resizeFile({
          file: image.file,
        });

        uploadFiles.push(resizedImage);
      }
    }
    setData({ ...data, uploadFiles: uploadFiles });
    images = null;
  };

  const onRemoveImage = index => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        let newImages = data.images;
        newImages.splice(index, 1);
        setData({
          ...data,
          images: newImages,
        });
      }
    });
  };

  const removeUploadFile = index => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        let newUploadFiles = data.uploadFiles;
        newUploadFiles.splice(index, 1);
        setData({
          ...data,
          uploadFiles: newUploadFiles,
        });
      }
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader
        title={data.id ? t("category.edit_category") : t("category.new_category")}
        action={
          deleteData && haveDeletePermission ? (
            <Box>
              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={showDeleteConfirm}>
                  {<Typography color={"error"}>{t("layout.delete")}</Typography>}
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box />
          )
        }
      />

      <CardContent>
        <DebugLogger title={"Category"} data={data} hidden={true} />
        <DebugLogger title={"Stores"} data={storesList} hidden={true} />

        <Typography
          sx={{
            pb: 2,
          }}
        >
          {t("layout.cover_image")}
        </Typography>

        <Box
          sx={{
            mb: 2,
            fontWight: "bold",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="caption">
            {t("page.maximum_upload_size")}: {maxUploadFileMb} MB
          </Typography>
          <Typography variant="caption">
            {t("page.accept_file_type")}: JPEG / JPG / PNG / WEBP / SVG
          </Typography>
        </Box>

        <Stack spacing={2}>
          {data.images.length > 0 &&
            data.images.map((image, index) => (
              <Stack key={index}>
                <Box
                  component={"img"}
                  src={image}
                  sx={{
                    objectFit: "contain",
                    maxWidth: "100%",
                    maxHeight: "200px",
                  }}
                />
                <Button
                  sx={{
                    p: 2,
                  }}
                  onClick={() => {
                    onRemoveImage(index);
                  }}
                >
                  {t("layout.remove")}
                </Button>
              </Stack>
            ))}

          {data.uploadFiles &&
            data.uploadFiles.length > 0 &&
            data.uploadFiles.map((file, index) => (
              <Stack>
                <Box
                  component={"img"}
                  src={URL.createObjectURL(file)}
                  sx={{
                    objectFit: "contain",
                    maxWidth: "100%",
                    maxHeight: "200px",
                  }}
                />
                <Button
                  sx={{
                    p: 2,
                  }}
                  onClick={() => {
                    removeUploadFile(index);
                  }}
                >
                  {t("layout.remove")}
                </Button>
              </Stack>
            ))}

          {showImageUploader && (
            <ImageUploader
              maxNumber={1}
              images={images}
              maxFileSize={1024 * 1024 * maxUploadFileMb}
              onImageChange={onImageChange}
              onRemoveImage={onRemoveImage}
              removeAllButton={false}
            />
          )}

          <TextField
            value={data.name}
            onChange={event => setData({ ...data, name: event.target.value })}
            required
            fullWidth
            autoFocus
            label={t("layout.name")}
          />

          <TextField
            type="number"
            value={data.priority}
            onChange={event => setData({ ...data, priority: event.target.value })}
            required
            fullWidth
            autoFocus
            label={t("category.priority")}
          />

          <Autocomplete
            multiple
            options={storesList}
            getOptionLabel={option => {
              return option.name;
            }}
            value={data.appliedStores || []}
            filterSelectedOptions
            isOptionEqualToValue={(opt, value) => {
              return opt.id === value.id;
            }}
            onChange={(event, newValue) => {
              setData({
                ...data,
                appliedStores: newValue.map(value => {
                  return value;
                }),
              });
            }}
            renderInput={params => (
              <TextField {...params} label={t("menu.stores")} />
            )}
          />

          <TextField
            fullWidth
            label={t("layout.description")}
            multiline
            value={data.description}
            onChange={event => {
              setData({ ...data, description: event.target.value });
            }}
            rows={4}
          />

          <UpdaterInfo data={data} />
        </Stack>

      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
