import { Box, Card, CardContent, CardHeader, Skeleton } from "@mui/material";
import { useEffect } from "react";

export default function CategorySkeleton({ row, colSpan }) {
  return (
    <Box>
      <Card variant="outlined">
        <CardHeader
          subheader={<Skeleton height={50} width={"30%"} animation="wave" />}
        ></CardHeader>
        <CardContent>
          <Skeleton height={40} animation="wave" />
          <Skeleton height={100} animation="wave" />
        </CardContent>
      </Card>
    </Box>
  );
}
