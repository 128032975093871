import { Box, ButtonBase, Card, Divider, Typography } from "@mui/material";
import {useState } from "react";
import PaymentInputDialog from "../dialog/PaymentInputDialog";

export default function PaymentMethodCard({ payment, addPayment, unpaid }) {
  const [showInput, setShowInput] = useState(false);

  const showPaymentInput = payment => {
    setShowInput(true);
  };

  const closePaymentInput = payment => {
    setShowInput(false);
  };

  const onSubmit = payment => {
    addPayment(payment);
    closePaymentInput();
  };

  return (
    <Box sx={{ width: "100%" }}>
      <ButtonBase
        sx={{ width: "100%" }}
        onClick={() => {
          showPaymentInput(payment);
        }}
      >
        <Card
          variant="outlined"
          sx={{
            height: 150,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            flexDirection: "column",
            // borderColor: palette.text.secondary,
          }}
        >
          <Box
            component={"img"}
            src={payment.icon}
            sx={{ width: "100%", height: "80px", p: 1, objectFit: "contain" }}
          />
          <Box width={"100%"}>
            <Divider />
            <Typography pt={1}>{payment.name}</Typography>
          </Box>
        </Card>
      </ButtonBase>
      {showInput && (
        <PaymentInputDialog
          open={showInput}
          onClose={closePaymentInput}
          onSubmit={onSubmit}
          payment={payment}
          unpaid={unpaid}
        />
      )}
    </Box>
  );
}
