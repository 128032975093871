import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid2 as Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { maxUploadFileMb } from "../../helper/GlobalValueHelper";
import DebugLogger from "../DebugLogger";
import ImageUploader from "../ImageUploader";
import ReactSwal from "../../helper/AlertHelper";
import { resizeFile } from "../../helper/UnitsHelper";

export default function images({ data, setData }) {
  const { t } = useTranslation();
  const [images, setImages] = useState([]);
  const [showImageUploader, setShowImageUploader] = useState(false);

  useEffect(() => {
    if (!data) return;
    let imageLength = data.images?.length;
    imageLength += data.uploadFiles?.length || 0;

    let variantLength = data.variants.length + 1; // one for cover image

    if (imageLength >= variantLength) {
      setShowImageUploader(false);
    } else {
      setShowImageUploader(true);
    }
  }, [data]);

  const onImageChange = async images => {
    let uploadFiles = data.uploadFiles || [];

    // check if file exists
    let fileExists = false;
    uploadFiles.forEach(file => {
      images.forEach(image => {
        if (file.name === image.file.name) {
          fileExists = true;
          return;
        }
      });
    });

    if (fileExists) {
      return;
    }

    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        if (image.file.size > 1024 * 1024 * maxUploadFileMb) {
          toast.error(`Image ${i + 1} file size must be less than ${maxUploadFileMb}MB`);
          return;
        }

        const resizedImage = await resizeFile({
          file: image.file,
        });

        uploadFiles.push(resizedImage);
      }
    }
    setData({ ...data, uploadFiles: uploadFiles });
    images = null;
  };

  const onRemoveImage = index => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        let newImages = data.images;
        newImages.splice(index, 1);
        setData({
          ...data,
          images: newImages,
        });
      }
    });
  };

  const removeUploadFile = index => {
    ReactSwal.fire({
      title: t("error.warning"),
      text: t("error.delete_confirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("layout.ok"),
      cancelButtonText: t("layout.cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        let newUploadFiles = data.uploadFiles;
        newUploadFiles.splice(index, 1);
        setData({
          ...data,
          uploadFiles: newUploadFiles,
        });
      }
    });
  };

  return (
    <Card variant="outlined">
      <CardHeader title={t("product.image")} />

      <CardContent>
        <DebugLogger title="Products" data={data} hidden={true}></DebugLogger>

        <Typography
          sx={{
            pb: 2,
          }}
        >
          {t("product.product_image")} ({t("product.cover_image_notification")})
        </Typography>

        <Box
          sx={{
            mb: 2,
            fontWight: "bold",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="caption">
            {t("page.maximum_upload_size")}: {maxUploadFileMb} MB
          </Typography>
          <Typography variant="caption">
            {t("page.accept_file_type")}: JPEG / JPG / PNG / WEBP / SVG
          </Typography>
        </Box>

          {data.images.length > 0 &&
            data.images.map((image, index) => (
                <Stack key={index}>
                  <Box
                    component={"img"}
                    src={image}
                    sx={{
                      objectFit: "contain",
                      maxWidth: "100%",
                      maxHeight: "200px",
                    }}
                  />
                  <Button
                    sx={{
                      p: 2,
                    }}
                    onClick={() => {
                      onRemoveImage(index);
                    }}
                  >
                    {t("layout.remove")}
                  </Button>
                </Stack>
            ))}

          {data.uploadFiles &&
            data.uploadFiles.length > 0 &&
            data.uploadFiles.map((file, index) => (

                <Stack key={index}>
                  <Box
                    component={"img"}
                    src={URL.createObjectURL(file)}
                    sx={{
                      objectFit: "contain",
                      maxWidth: "100%",
                      maxHeight: "200px",
                    }}
                  />
                  <Button
                    sx={{
                      p: 2,
                    }}
                    onClick={() => {
                      removeUploadFile(index);
                    }}
                  >
                    {t("layout.remove")}
                  </Button>
                </Stack>
            ))}
        {showImageUploader && (
          <ImageUploader
            maxNumber={data.variants.length + 1}
            images={images}
            maxFileSize={1024 * 1024 * maxUploadFileMb}
            onImageChange={onImageChange}
            onRemoveImage={onRemoveImage}
            removeAllButton={false}
          />
        )}
      </CardContent>

      <CardActions sx={{ justifyContent: "space-between", px: 2 }}></CardActions>
    </Card>
  );
}
