import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Container,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { useSelector } from "react-redux";
import { FormatHelper } from "../../helper/FormatHelper";
import { checkPermission } from "../../helper/PermissionHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// icons
import SearchIcon from "@mui/icons-material/Search";

// components
import DebugLogger from "../../components/DebugLogger";
import TableSkeleton from "../../components/TableSkeleton";
import FilterButton from "../../components/order/FilterButton";

// api
import { OrderApi } from "../../api/OrderApi";
import { StoreApi } from "../../api/StoreApi";

export default function List() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get("page")) || 1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState([]);
  const [searchStr, setSearchStr] = useState();
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [haveUpdatePermission, setHaveUpdatePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const [colSpan, setColSpan] = useState(9);
  const [storeList, setStoreList] = useState([]);

  useEffect(() => {
    fetchData();
    fetchStoreList();
  }, [page]);

  useEffect(() => {
    const updatePermission = checkPermission(currentUser, currentPage, "update");
    setHaveUpdatePermission(updatePermission);
  }, [currentUser, currentPage]);

  useEffect(() => {
    if (!filter || filter.length <= 0) return;
    setPage(1);
    fetchData();
  }, [filter]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await OrderApi.list(page, pageSize, filter);
      setData(response.data);
      setTotal(response.total);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStoreList = async () => {
    try {
      const response = await StoreApi.list(1, -1, []);

      if (response.data) {
        setStoreList(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const viewDetail = id => {
    navigate(`/orders/${id}`);
  };

  const onChangePage = value => {
    setPage(value);
    setSearchParams({ page: value });
  };

  const onChangeSearchField = value => {
    setSearchStr(value);
  };

  const search = () => {
    setPage(1);

    let newFilter = filter.filter(item => item.field != "order_num");
    newFilter.unshift({ field: "order_num", value: searchStr });
    setFilter(newFilter);
  };

  const checkFilter = () => {
    let show = false;
    filter.forEach(item => {
      if (item.value) show = true;
    });

    return show;
  };

  const filterLabel = () => {
    let label = [];

    filter.forEach(item => {
      if (!item.value) return;

      if (item.field == "order_num") {
        label.push(`${t("order.order_num")}: ${item.value}`);
      }

      if (item.field == "created_at" && item.op == "gte") {
        label.push(
          `${t("layout.start_date")}: ${moment(item.value).format("YYYY-MM-DD HH:mm:ss")}`,
        );
      }

      if (item.field == "created_at" && item.op == "lte") {
        label.push(`${t("layout.end_date")}: ${moment(item.value).format("YYYY-MM-DD HH:mm:ss")}`);
      }

      if (item.field == "pay_status") {
        label.push(`${t("order.payment_status")}: ${getPayStatus(item.value)}`);
      }

      if (item.field == "ship_status") {
        label.push(`${t("order.ship_status")}: ${getShipStatus(item.value)}`);
      }

      if (item.field == "status") {
        label.push(`${t("layout.status")}: ${t(`order.${item.value}`)}`);
      }

      if (item.field == "customer_name") {
        label.push(`${t("order.member_name")}: ${item.value}`);
      }

      if (item.field == "member_phone") {
        label.push(`${t("order.member_phone")}: ${item.value}`);
      }

      if (item.field == "member_email") {
        label.push(`${t("order.member_email")}: ${item.value}`);
      }

      if (item.field == "store_id") {
        label.push(`${t("menu.stores")}: ${storeList.find(x => x.id == item.value).name || ""}`);
      }

      if (item.field == "member_customer_num") {
        label.push(`${t("order.member_num")}: ${item.value}`);
      }
    });

    return label.join(", ");
  };

  const getPayStatus = status => {
    switch (status) {
      case "full_paid":
        return t("order.paid");
      case "partial_paid":
        return t("order.partial_paid");
      case "unpaid":
        return t("order.unpaid");
      default:
        return t("order.unpaid");
    }
  };

  const getShipStatus = status => {
    switch (status) {
      case "delivered":
        return t("order.delivered");
      case "dispatch":
        return t("order.dispatch");
      case "unship":
        return t("order.unship");
      default:
        return t("order.unship");
    }
  };

  return (
    <Container maxWidth={false}>
      <Card variant="outlined">
        <CardHeader
          title={<Typography variant="h6">{t("menu.orders")}</Typography>}
        />

        <CardContent>
          <DebugLogger title="Orders" data={filter} hidden={true}></DebugLogger>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              pb: 1,
            }}
          >
            <Box>{checkFilter() && <Chip label={filterLabel()} />}</Box>
            <FilterButton searchStr={searchStr} setFilter={setFilter} storeList={storeList}/>
            <TextField
              placeholder={t("layout.search_by_order_number")}
              onChange={event => {
                onChangeSearchField(event.target.value);
              }}
              onKeyDown={event => {
                if (event.key == "Enter") {
                  search();
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    edge="end"
                    onClick={() => {
                      search();
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
          <TableContainer elevation={0} component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell width={"10%"}>{t("order.order_num")}</TableCell>
                  <TableCell width={"10%"}>{t("layout.date")}</TableCell>
                  <TableCell width={"10%"}>{t("order.member_name")}</TableCell>
                  <TableCell width={"10%"}>{t("order.paid")}</TableCell>
                  <TableCell width={"10%"}>{t("layout.final_price")}</TableCell>
                  <TableCell width={"10%"}>{t("menu.stores")}</TableCell>
                  <TableCell width={"10%"}>{t("order.payment_status")}</TableCell>
                  <TableCell width={"10%"}>{t("order.ship_status")}</TableCell>
                  <TableCell width={"10%"}>{t("order.order_status")}</TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableSkeleton row={pageSize} colSpan={colSpan} />
              ) : (
                <TableBody>
                  {!data.length && (
                    <TableRow>
                      <TableCell colSpan={colSpan} align="center">
                        {t("layout.no_data")}
                      </TableCell>
                    </TableRow>
                  )}
                  {data.map(row => (
                    <TableRow
                      hover
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      onClick={() =>
                        haveUpdatePermission
                          ? viewDetail(row.id)
                          : toast.error(t("error.no_update_permission"))
                      }
                    >
                      <TableCell component="th" scope="row">
                        {row.orderNum}
                      </TableCell>
                      <TableCell>{row.updatedAt}</TableCell>
                      <TableCell>{row.customerName}</TableCell>
                      <TableCell>{FormatHelper.formatAmount(row.paidTotal)}</TableCell>
                      <TableCell>{FormatHelper.formatAmount(row.finalTotal)}</TableCell>
                      <TableCell>{row.storeName || ""}</TableCell>
                      <TableCell>
                        <Chip
                          variant="outlined"
                          color={row.payStatus == "full_paid" ? "success" : "error"}
                          label={getPayStatus(row.payStatus)}
                        />
                      </TableCell>
                      <TableCell>
                        <Chip
                          variant="outlined"
                          color={row.shipStatus == "delivered" ? "success" : "error"}
                          label={getShipStatus(row.shipStatus)}
                        />
                      </TableCell>
                      <TableCell>
                        <Chip
                          variant="outlined"
                          color={row.status == "complete" ? "success" : "error"}
                          label={t(`order.${row.status}`)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </CardContent>

        <CardActions sx={{ justifyContent: "space-between", px: 2 }}>
          <Typography variant="body2">
            {t("layout.total")}: {total}
          </Typography>
          <Stack spacing={2}>
            <Pagination
              page={page}
              count={Math.ceil(total / pageSize)}
              shape="rounded"
              onChange={(event, value) => {
                onChangePage(value);
              }}
            />
          </Stack>
        </CardActions>
      </Card>
    </Container>
  );
}
