import {
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getVariantName } from "../../helper/UnitsHelper";

// date picker
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

export default function Service({ data, setData }) {
  const { t } = useTranslation();

  useEffect(() => {
    let services = [];
    data.variants.forEach((variant, index) => {
      if (!variant.id) {
        variant.tempId = index + 1;
        services.push({
          tempId: variant.tempId,
          quota: 0,
          expireType: "fix",
          expireDate: null,
          expirePeriod: 0,
        });
      } else {
        const service = data.services.find(service => service.variantId === variant.id);

        if (service) {
          services.push(service);
        }
      }
    });

    setData({
      ...data,
      services: services,
    });
  }, [data.variants]);

  const findVariant = service => {
    console.log(data.variants.find(variant => variant.tempId === service.tempId));
    if (service.tempId) {
      return data.variants.find(variant => variant.tempId === service.tempId);
    } else {
      return data.variants.find(variant => variant.id === service.variantId);
    }
  };

  return (
    <Card variant="outlined">
      <CardHeader title={t("product.service")} />

      <CardContent>
        <TableContainer elevation={0} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {!product.isSimple && <TableCell>{t("layout.name")}</TableCell>}
                <TableCell>{t("member.quota")}</TableCell>
                <TableCell>{t("layout.type")}</TableCell>
                <TableCell>{t("layout.date")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.services.map((service, index) => (
                <TableRow
                  hover
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {!data.isSimple && (
                    <TableCell key={index} component="th" scope="row">
                      {findVariant(service) && getVariantName(findVariant(service))}
                    </TableCell>
                  )}

                  <TableCell>
                    <TextField
                      variant="outlined"
                      type={"number"}
                      value={service.quota || 0}
                      onChange={event => {
                        let newServices = [...data.services];
                        newServices[index].quota = parseInt(event.target.value);
                        setData({ ...data, services: newServices });
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                      autoWidth={false}
                      value={service.expireType || ""}
                      sx={{ width: 200 }}
                      onChange={event => {
                        let newServices = [...data.services];
                        newServices[index].expireType = event.target.value;
                        setData({ ...data, services: newServices });
                      }}
                    >
                      <MenuItem value={"fix"}>{t("product.fix")}</MenuItem>
                      <MenuItem value={"period"}>{t("product.period")}</MenuItem>
                    </Select>
                  </TableCell>
                  {service.expireType == "period" ? (
                    <TableCell>
                      <TextField
                        variant="outlined"
                        type={"number"}
                        value={service.expirePeriod || ""}
                        onChange={event => {
                          let newServices = [...data.services];
                          newServices[index].expirePeriod = event.target.value;
                          setData({ ...data, services: newServices });
                        }}
                      />
                    </TableCell>
                  ) : (
                    <TableCell>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          value={moment(data.services[index].expireDate || "")}
                          onChange={newValue => {
                            if (!newValue) {
                              return;
                            }
                            let newServices = [...data.services];
                            newServices[index].expireDate = newValue.format("YYYY-MM-DD");
                            setData({ ...data, services: newServices });
                          }}
                          inputFormat="YYYY-MM-DD"
                          renderInput={params => <TextField fullWidth {...params} />}
                        />
                      </LocalizationProvider>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
