import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import {
  getSupplierPageName,
} from "../helper/GlobalValueHelper";
import Create from "../views/supplier/Create";
import Edit from "../views/supplier/Edit";
import List from "../views/supplier/List";

const SupplierRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/suppliers",
      element: (
        <AuthProvider page={getSupplierPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "/suppliers/create",
      element: (
        <AuthProvider page={getSupplierPageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
    {
      path: "/suppliers/:id",
      element: (
        <AuthProvider page={getSupplierPageName()} action={"update"}>
          <Edit />
        </AuthProvider>
      ),
    },
  ],
};

export default SupplierRoute;
