import axios from "../helper/AxiosHelper";

const getSummary = async () => {
  const response = await axios.get(`/dashboard`);
  const result = response.data.result;

  console.log( 'response', response);

  return result.map((item) => {
    return {
      type: item.type,
      rangeCode: item.range_code,
      dateRange: item.date_range,
      result: item.result,
    };
  });
};

const getYearReport = async () => {
  const response = await axios.get(`/report/getYearReport?`);
  const result = response.data.result;

  return {
    dateRange: result.date_range,
    result: result.result.map((item) => {
      return {
        year: item.year,
        orderCount: item.order_count,
        orderAmount: item.order_amount,
      };
    }),
    type: result.type,
    rangeType: result.range_type,
  };
};

const getMonthReport = async () => {
  const response = await axios.get(`/report/getMonthReport?`);
  const result = response.data.result;

  return {
    dateRange: result.date_range,
    result: result.result.map((item) => {
      return {
        month: item.month,
        orderCount: item.order_count,
        orderAmount: item.order_amount,
      };
    }),
    type: result.type,
    rangeType: result.range_type,
  };
};

const getDateReport = async (endDate) => {
  const response = await axios.get(
    `/report/getDateReport?end_date=${endDate}`
  );
  const result = response.data.result;

  return {
    dateRange: result.date_range,
    result: result.result.map((item) => {
      return {
        date: item.date,
        orderCount: item.order_count,
        orderAmount: item.order_amount,
      };
    }),
    type: result.type,
    rangeType: result.range_type,
  };
};

export const DashboardApi = {
  getSummary,
  getYearReport,
  getMonthReport,
  getDateReport,
};
