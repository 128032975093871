import { Translation } from "react-i18next";
import ReactSwal from "../helper/AlertHelper";
import { getDeviceSecretKey, removeLocalStorageUser } from "./LocalStorageHelper";
import { getWellcomePageName } from "./GlobalValueHelper";

// role 0 = no any permission
// role 1 = admin
// role 2 = manager
// role 3 = staff

const isAdmin = 1;
// const isManager = 2;
// const isStaff = 3;
// const isTester = 3;

export const checkPermission = (user, page, action) => {
  try {
    if (page == getWellcomePageName()) {
      return true;
    }
    var permissionList = [];

    if (!user) {
      return false;
    }

    if (!user.permissions || user.permissions.length == 0) {
      return false;
    }

    for (const [key, value] of Object.entries(user.permissions)) {
      permissionList.push({
        page: key,
        action: value,
      });
    }

    var havePermission = false;

    permissionList.forEach(permission => {
      if (permission.page == page && permission.action.includes(action)) {
        havePermission = true;
        return;
      }
    });

    return havePermission;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const checkPagePermission = (user, page, action, navigate) => {
  if (page == getWellcomePageName()) {
    return;
  }

  if (!checkPermission(user, page, action)) {
    navigate("/");
    ReactSwal.fire({
      title: <Translation>{t => <>{t("error.title")}</>}</Translation>,
      html: <Translation>{t => <>{t("error.no_permission")}</>}</Translation>,
      allowOutsideClick: false,
    });
  }
};

// export const getRoleLabel = (t, role) => {
//   role = parseInt(role);

//   switch (role) {
//     case isAdmin:
//       return t("role.admin");
//     case isManager:
//       return t("role.manager");
//     case isMarketing:
//       return t("role.marketing");
//     case isStaff:
//       return t("role.staff");
//     case isOperation:
//       return t("role.operation");
//     case isSpare:
//       return t("role.spare");
//     default:
//       return "";
//   }
// };

// export const getRoleList = (role) => {
//   return [isAdmin, isManager, isMarketing, isStaff, isOperation, isSpare];
// };

// export const checkDeviceLock = user => {
//   let locked = false;

//   if (user.roles && user.roles.includes(isAdmin)) {
//     locked = false;
//     return;
//   }

//   const key = getDeviceSecretKey();

//   if (key) {
//     locked = false;
//   } else {
//     locked = true;
//   }

//   if (locked) {
//     removeLocalStorageUser();
//     ReactSwal.fire({
//       title: <Translation>{t => <>{t("error.title")}</>}</Translation>,
//       html: <Translation>{t => <>{t("error.no_permission")}</>}</Translation>,
//       allowOutsideClick: false,
//     });
//   }
//   return locked;
// };
