import { Box, Button } from "@mui/material";
import { useState } from "react";
import { CSVDownload } from "react-csv";
import { useTranslation } from "react-i18next";

// icons
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// api
import { MemberApi } from "../../api/MemberApi";

export default function ExportButton() {
  const { t } = useTranslation();
  const [csvData, setCsvData] = useState([]);
  const [downloaded, setDownloaded] = useState(false);

  const download = async () => {
    try {
      const response = await MemberApi.list(1, -1, []);
      const data = response.data.map(item => {
        return {
          email: item.email || "",
          first_name: item.firstName || "",
          last_name: item.lastName || "",
          phone_number: item.phoneNumber || "",
          remark: item.remark || "",
        };
      });

      setCsvData(data);
      setDownloaded(true);

      setTimeout(() => {
        setDownloaded(false);
      }, 5000); // every 5 seconds download again
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <Button
        color="inherit"
        variant="outlined"
        onClick={download}
        startIcon={<FileDownloadIcon />}
      >
        {t("layout.export")}
      </Button>
      {downloaded && <CSVDownload data={csvData} target="_blank" uFEFF={true}></CSVDownload>}
    </Box>
  );
}
