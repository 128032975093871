import AuthProvider from "../components/AuthProvider";
import DashboardLayout from "../components/DashboardLayout";
import { getCategoryPageName } from "../helper/GlobalValueHelper";
import Create from "../views/category/Create";
import Edit from "../views/category/Edit";
import List from "../views/category/List";

const CategoryRoute = {
  element: <DashboardLayout />,
  children: [
    {
      path: "/category",
      element: (
        <AuthProvider page={getCategoryPageName()} action={"view"}>
          <List />
        </AuthProvider>
      ),
    },
    {
      path: "/category/:id",
      element: (
        <AuthProvider page={getCategoryPageName()} action={"update"}>
          <Edit />
        </AuthProvider>
      ),
    },
    {
      path: "/category/create",
      element: (
        <AuthProvider page={getCategoryPageName()} action={"create"}>
          <Create />
        </AuthProvider>
      ),
    },
  ],
};

export default CategoryRoute;
