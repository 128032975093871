import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Translation, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

// helper
import { checkPermission } from "../../helper/PermissionHelper";
import { getPlaceHolderImage } from "../../helper/UnitsHelper";
import { pageName } from "../../reducers/commonSlice";
import { user } from "../../reducers/userSlice";

// icons
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";

// components
import DebugLogger from "../../components/DebugLogger";
import TableSkeleton from "../../components/TableSkeleton";

// api
import { CategoryApi } from "../../api/CategoryApi";

export default function List() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(parseInt(searchParams.get("page")) || 1);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState();
  const [searchStr, setSearchStr] = useState();
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [haveCreatePermission, setHaveCreatePermission] = useState(false);
  const [haveUpdatePermission, setHaveUpdatePermission] = useState(false);
  const currentUser = useSelector(user);
  const currentPage = useSelector(pageName);
  const [colSpan, setColSpan] = useState(6);

  useEffect(() => {
    const createPermission = checkPermission(currentUser, currentPage, "create");
    setHaveCreatePermission(createPermission);

    const updatePermission = checkPermission(currentUser, currentPage, "update");
    setHaveUpdatePermission(updatePermission);
  }, [currentUser, currentPage]);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    if (!filter || filter.length <= 0) return;
    setPage(1);
    fetchData();
  }, [filter]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await CategoryApi.list(page, pageSize, filter);
      setData(response.data);
      setTotal(response.total);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createProduct = id => {
    navigate(`/category/create`);
  };

  const viewDetail = id => {
    navigate(`/category/${id}`);
  };

  const onChangePage = value => {
    setPage(value);
    setSearchParams({ page: value });
  };

  const onChangeSearchField = value => {
    setSearchStr(value);
  };

  const search = () => {
    setPage(1);
    setFilter([{ field: "name", value: searchStr }]);
  };

  return (
    <Container maxWidth={false}>
      <Card variant="outlined">
        <CardHeader
          title={
            <Translation>
              {t => <Typography variant="h6">{t("category.category")}</Typography>}
            </Translation>
          }
          action={
            haveCreatePermission && (
              <Button
                color="inherit"
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={createProduct}
              >
                <Translation>{t => t("category.new_category")}</Translation>
              </Button>
            )
          }
        />

        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              pb: 1,
            }}
          >
            <TextField
              placeholder={t("layout.search_by_title")}
              onChange={event => {
                onChangeSearchField(event.target.value);
              }}
              onKeyDown={event => {
                if (event.key == "Enter") {
                  search();
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    edge="end"
                    onClick={() => {
                      search();
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                ),
              }}
            />
          </Box>
          <TableContainer elevation={0} component={Paper}>
            <DebugLogger title="Category" data={data} hidden={true}></DebugLogger>

            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell width={"5%"}>{t("layout.id")}</TableCell>
                  <TableCell width={"5%"}>{t("layout.cover_image")}</TableCell>
                  <TableCell width={"10%"}>{t("layout.name")}</TableCell>
                  <TableCell width={"20%"}>{t("menu.stores")}</TableCell>
                  <TableCell width={"10%"}>{t("setting.updated_by")}</TableCell>
                  <TableCell width={"10%"}>{t("setting.updated_at")}</TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableSkeleton row={pageSize} colSpan={colSpan} />
              ) : (
                <TableBody>
                  {!data.length && (
                    <TableRow>
                      <TableCell colSpan={colSpan} align="center">
                        {t("layout.no_data")}
                      </TableCell>
                    </TableRow>
                  )}
                  {data.map((row, index) => (
                    <TableRow
                      hover
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}
                      onClick={() =>
                        haveUpdatePermission
                          ? viewDetail(row.id)
                          : toast.error(t("error.no_update_permission"))
                      }
                    >
                      <TableCell component="th" scope="row">
                        {row.id}
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                          <Avatar
                            variant="rounded"
                            sx={{ border: "1px solid #0000001f" }}
                            alt={`category_cover_image_${index}`}
                            src={row.coverImage || getPlaceHolderImage()}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.appliedStores.map(store => store.name).join(", ")}</TableCell>
                      <TableCell>{row.updatedBy}</TableCell>
                      <TableCell>{row.updatedAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </CardContent>

        <CardActions sx={{ justifyContent: "space-between", px: 2 }}>
          <Typography variant="body2">
            {t("layout.total")}: {total}
          </Typography>
          <Stack spacing={2}>
            <Pagination
              page={page}
              count={Math.ceil(total / pageSize)}
              shape="rounded"
              onChange={(event, value) => {
                onChangePage(value);
              }}
            />
          </Stack>
        </CardActions>
      </Card>
    </Container>
  );
}
