import {
  Autocomplete,
  Box,
  Collapse,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

// icons
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// api
import { useTranslation } from "react-i18next";
import { ProductApi } from "../../api/ProductApi";
import { getVariantName, timeout } from "../../helper/UnitsHelper";

export default function Bundle({ data, setData }) {
  const { t } = useTranslation();
  const [openConditions, setOpenConditions] = useState(true);
  const [products, setProducts] = useState([]);
  const [variants, setVariants] = useState([]);
  const [filter, setFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleOpenConditions = () => {
    setOpenConditions(!openConditions);
  };

  useEffect(() => {
    setData({
      ...data,
      parameter: {
        variants: data.bundle.selectedVariant,
        qty: data.bundle.qty,
        amount: data.bundle.amount,
      },
    });

    // to fix MUI: The value provided to Autocomplete is invalid.None of the options match
    setFilter([]);
  }, [data.bundle]);

  useEffect(() => {
    if (!isLoading) {
      fetchProducts();
    }
  }, [filter]);

  const search = async value => {
    setFilter([{ field: "title", value: value, op: "like" }]);
  };

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      await timeout(1000);
      const response = await ProductApi.list(1, 10, filter);
      setProducts(response.data);
      let items = [];

      response.data.forEach(product => {
        if(product.variants){
          product.variants.forEach(variant => {
            items.push({
              id: variant.id,
              title: `${product.title} - ${getVariantName(variant)}`,
            });
          });
        }
      });

      setVariants([...items, ...data.bundle.selectedVariant]);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <Box component={"div"}>
      {/* Conditions */}
      <ListItemButton onClick={handleOpenConditions}>
        <ListItemText primary={t("promotion.condition")} />
        {openConditions ? <RemoveIcon /> : <AddIcon />}
      </ListItemButton>
      <Collapse in={openConditions} timeout="auto" unmountOnExit>
        <Box px={2} py={1}>
          <Autocomplete
            multiple
            loading={isLoading}
            disableClearable={true}
            options={variants}
            inputValue={inputValue}
            onInputChange={(event, value, reason) => {
              if (event && event.type === "blur") {
                setInputValue("");
              } else if (reason !== "reset") {
                search(value);
                setInputValue(value);
              }
            }}
            disableCloseOnSelect
            value={data.bundle.selectedVariant || []}
            getOptionLabel={option => {
              return option.title;
            }}
            filterSelectedOptions
            isOptionEqualToValue={(opt, value) => {
              return opt.id === value.id;
            }}
            onChange={(event, newValue) => {
              console.log(newValue);
              setData({
                ...data,
                bundle: {
                  ...data.bundle,
                  selectedVariant: newValue,
                },
              });
            }}
            renderInput={params => (
              <TextField required {...params} label={t("layout.search_by_title")} />
            )}
          />
        </Box>
        <Box px={2} py={1}>
          <TextField
            value={data.bundle.qty}
            onChange={e => {
              setData({
                ...data,
                bundle: {
                  ...data.bundle,
                  qty: e.target.value,
                },
              });
            }}
            fullWidth
            label={t("promotion.qty")}
            type="number"
          />
        </Box>
        <Box px={2} py={1}>
          <TextField
            value={data.bundle.amount}
            onChange={e => {
              setData({
                ...data,
                bundle: {
                  ...data.bundle,
                  amount: e.target.value,
                },
              });
            }}
            fullWidth
            label={`${t("order.amount")} (${t("promotion.bundle")})`}
            type="number"
          />
        </Box>
      </Collapse>
    </Box>
  );
}
